// import lib
import isEmpty from "../../lib/isEmpty";

const validation = (value) => {
  let errors = {};
  // var letters = /^[A-Za-z]+$/;
  var letters = /^[a-zA-Z ]*$/;
  if (isEmpty(value.bankName)) {
    errors.bankName = "Bankname Required";
  }
  if (!value.bankName.match(letters)) {
    errors.bankName = "Only Alphabets Allowed";
  }

  if (isEmpty(value.accountNo)) {
    errors.accountNo = "Account No Required";
  }

  if (isEmpty(value.holderName)) {
    errors.holderName = "Account holder name Required";
  }
  if (!value.holderName.match(letters)) {
    errors.holderName = "Only Alphabets Allowed";
  }

  if (isEmpty(value.bankcode)) {
    errors.bankcode = "Bank code Required";
  }

  if (isEmpty(value.country)) {
    errors.country = "Country Required";
  }

  if (isEmpty(value.city)) {
    errors.city = "City Required";
  }

  return errors;
};

export default validation;
