// import package
import React, { useEffect } from "react";
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";

// import component
import ConditionRoute from "./components/Route/ConditionRoute";
import i18n from "./components/i18next/i18n";
import HelperRoute from "./components/Route/HelperRoute";
import Contact from "./pages/ContactUs";
// import Context
import SocketContext from "./components/Context/SocketContext";

// pages for this product
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import P2pwallet from "./pages/P2pwallet";
import Register from "pages/register.js";
import ForgotPassword from "pages/forgot-password.js";
import EmailVerification from "./pages/EmailVerification";
import ProfilePage from "./pages/profile";
import SettingPage from "./pages/settings";
import Kyc from "./pages/kyc";
import DashboardPage from "./pages/DashboardPage";
import WalletPage from "./pages/WalletPage";
import Staking from "./pages/staking";
import Spot from "./pages/spot";
import Derivative from "./pages/derivative";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AboutUsPage from "./pages/AboutUsPage";
import FaqPage from "./pages/FaqPage";
import PressPage from "./pages/PressPage";

import TermsPage from "./pages/TermsPage";
import Disclaimer from "./pages/disclaimer";
import Refund from "./pages/refund";
import P2PTradeHistory from "./pages/P2ptradeHistoryPage";

import P2P from "./pages/p2p";
import Postmyad from "./pages/postmyad";
import Peertopeer from "./pages/peertopeer";
import Comingsoon from "./pages/commingsoon";
import MyAds from "./components/MyAds";
import Chat from "./pages/p2pchat";

import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";

import ChartPage from "./pages/chart";

import ChartAPP from "./components/SportTrade/Chart_App";

import History from "./pages/history";
import SupportPage from "./pages/SupportPage";

// import action
import { decodeJwt } from "./actions/jsonWebToken";

// import config
import { socket } from "./config/socketConnectivity";

// import lib
import store from "./store";
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";
import Cryptonews from "./pages/cryptonews";

/* import jwt_decode from "jwt-decode";
import { toastAlert } from "./lib/toastAlert"; */

const App = () => {
  const { isAuth } = store.getState().auth;

  useEffect(() => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }
  }, []);

  /*   const LogoutSocketUser = () => {
    socket.on("logouttrigger", async function (data) {
      if (data) {
        if (localStorage.user_token) {
          const checkData = await jwt_decode(
            localStorage.user_token,
            store.dispatch
          );

          console.log(checkData,"====>checkData")
          if (checkData && checkData._id == data.toString()) {
            localStorage.removeItem("user_token");
            toastAlert("error", "Your account was blocked by Admin", "watch");
            setTimeout(() => window.location.reload(), 3000);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (localStorage.user_token) {
      decodeJwt(localStorage.user_token, store.dispatch);
    }
    LogoutSocketUser();
  }, [LogoutSocketUser]); */

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename="/">
          <SocketContext.Provider value={{ socket }}>
            <ToastContainer />
            <HelperRoute />
            <Switch>
              <ConditionRoute
                exact
                path="/"
                component={HomePage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/home"
                component={HomePage}
                type={"public"}
              />
              {/* 
                            <Route exact path='/launchpad' component={Launchpad} type={"public"} />
                            <Route exact path='/launchpaddetails' component={LaunchpadDetails} type={"public"} /> */}

              <ConditionRoute
                exact
                path="/p2ptradehistory"
                component={P2PTradeHistory}
                type={"private"}
              />

              <ConditionRoute
                exact
                path="/p2p"
                component={P2P}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/postmyad"
                component={Postmyad}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/peertopeer"
                component={Peertopeer}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/myads"
                component={MyAds}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/chat/:id"
                component={Chat}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/staking"
                component={Staking}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/history"
                component={History}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/support-ticket"
                component={SupportPage}
                type={"private"}
              />

              <ConditionRoute
                exact
                path="/login"
                component={LoginPage}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/login/:authToken"
                component={LoginPage}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/register"
                component={Register}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/recover-password"
                component={ForgotPassword}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/reset-password/:authToken"
                component={ResetPasswordPage}
                type={"auth"}
              />
              <ConditionRoute
                exact
                path="/P2pwallet"
                component={P2pwallet}
                type={"private"}
              />

              {/* PRIVATE */}

              <ConditionRoute
                exact
                path="/profile"
                component={ProfilePage}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/settings"
                component={SettingPage}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/dashboard"
                component={DashboardPage}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/wallet"
                component={WalletPage}
                type={"private"}
              />
              <ConditionRoute
                exact
                path="/kyc"
                component={Kyc}
                type={"private"}
              />

              {/* PRIVATE */}

              {/* PUBLIC */}
              <ConditionRoute
                exact
                path="/spot/:tikerRoot?"
                component={Spot}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/derivative/:tikerRoot?"
                component={Derivative}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/chart"
                component={ChartPage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/chartApp/:pairs/:themeData"
                component={ChartAPP}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/about"
                component={AboutUsPage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/faq"
                component={FaqPage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/press"
                component={PressPage}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/terms"
                component={TermsPage}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/refund"
                component={Refund}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/disclaimer"
                component={Disclaimer}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/comingsoon"
                component={Comingsoon}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/privacy-policy"
                component={PrivacyPolicyPage}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/email-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-fiat-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-coin-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/verify-old-email/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/verify-new-email/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/verify-newadd-email/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-fiat-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />
              <ConditionRoute
                exact
                path="/withdraw-coin-verification/:authToken"
                component={EmailVerification}
                type={"public"}
              />

              <ConditionRoute
                exact
                path="/contact"
                component={Contact}
                type={"public"}
              />

              {/* PUBLIC */}

              {/* <Route exact path="/*" component={Home}>
                    <Redirect to="/home" />
                </Route> */}
            </Switch>
          </SocketContext.Provider>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
