// import package
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import component
import OrderPlaceModalLocked from "./OrderPlaceModalLocked";

// import action
import { getStaking } from "../../actions/stakingAction";

// import lib
import { interestByDays } from "../../lib/calculation";
import { toFixed } from "../../lib/roundOf";
import isEmpty from "../../lib/isEmpty";
import $ from "jquery";
const initialFormValue = {
  isModalOpen: false,
  record: {},
  durationdays: 0,
};

const StakingList = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  // state
  const [list, setList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [stakeData, setStakeData] = useState(initialFormValue);
  const [intrest_per, setintrest_per] = useState();
  const [intrest, setintrest] = useState();
  const [indexval, setindexval] = useState();
  const [durationdays, setdurationdays] = useState(0);

  // redux-state
  const { isAuth } = useSelector((state) => state.auth);

  // function
  const fetchStaking = async () => {
    try {
      const { status, loading, message, result } = await getStaking();
      setLoader(loading);
      if (status == "success") {
        setList(result);
      }
    } catch (err) {}
  };

  const handleCloseModal = () => {
    setStakeData({ isModalOpen: false, record: {} });
  };

  useEffect(() => {
    fetchStaking();
  }, []);

  const durationloop = (durations, mainindex) => {
   
    let arr = [];
    if (!isEmpty(durations) && durations.length > 0) {
      durations.map((duration, index, array) => {

        if (index == array.length - 1) {
          arr.push(
            <span className="daysLocked" id={"act_"+mainindex+index}
              onClick={() => {
                onClick_Days(duration, mainindex,index);
              }}
            >
              {" "}
              {duration.days}
            </span>
          );
        } else {
          arr.push(
            <span className={`daysLocked ${index==0 ? "active" : ""}`} id={"act_"+mainindex+index}
              onClick={() => {
                onClick_Days(duration, mainindex,index);
              }}
            >
              {" "}
              {duration.days}
            </span>
          );
        }
      });

      return arr;
    }
  };

  const onClick_Days = (duration, index,inc) => {
    console.log(index,inc, "durationdurationdurationduration");
    $(".daysLocked").removeClass("active");


    $("#act_"+index+inc).addClass("active");  

 
    setintrest_per(duration.APY);

    setintrest(toFixed(interestByDays(1000, duration.APY, 365), 4));
    setindexval(index);
    setdurationdays(duration.days);
  };
  // console.log(list,'listlistlist')
  return (
    <div className="dashboard_box">
      <div class="MuiGrid-root makeStyles-grid-33  MuiGrid-container">
        <div class="MuiGrid-root makeStyles-grid-34  MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-5 MuiGrid-grid-lg-5">
          <h3 class="dash_title">Locked Staking</h3>
        </div>
      </div>

      <OrderPlaceModalLocked
        isShow={stakeData.isModalOpen}
        record={stakeData.record}
        onHide={handleCloseModal}
        durationdays={stakeData.durationdays}
      />

      <div className="table-responsive stakingCurrencyList">
        <table className="table table-striped mb-0 lockedTable">
          <thead>
            <tr>
              <th>Coin</th>
              <th>{t("APY")}</th>
              <th>Locked Interest Per Thousand</th>
              <th>Duration (Days)</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!loader &&
              list &&
              list.length > 0 &&
              list.map((item, key) => {
                if (item.type.includes("fixed")) {

                  let noOfDays = item.periodList[0].days;
                  let interest = item.periodList[0].APY
                  
                  if(key == indexval) {
                    noOfDays = durationdays;
                    interest = intrest_per
                  }
                  console.log(key,indexval,durationdays,intrest_per,noOfDays,interest,item,'keykeykey')
                  return (
                    <tr key={key}>
                      <td>
                        <img src={item.image} alt="" className="img-fluid" />
                        {item.name}
                      </td>
                      <td className="textDepositGreen">
                        {intrest_per && indexval == key
                          ? intrest_per
                          : item.periodList[0].APY}
                        %
                      </td>
                      {/* <td>{item.flexibleAPY}%</td> */}
                      <td className="textDepositGreen">
                        {/*{intrest && indexval == key
                            ? intrest
                            : toFixed(
                                (noOfDays  * interestByDays(1000,interest, 365)),
                                4
                        )}{" "}*/}
                      { intrest && indexval == key ? 
                        toFixed((noOfDays  * interestByDays(1000,interest, 365)),4):toFixed((noOfDays  * interestByDays(1000,interest, 365)),4)}{" "} 
                      {item.currencySymbol}
                      </td>

                      <td>{durationloop(item.periodList, key)}</td>
                      <td>
                        <button
                          className="btn btn-primary text-uppercase py-2 m-0"
                          onClick={() => {
                            if (isAuth) {
                              setStakeData({
                                isModalOpen: true,
                                record: item,
                                durationdays:
                                  durationdays > 0 && indexval == key
                                    ? durationdays
                                    : item.periodList[0].days,
                              });
                            } else {
                              history.push("/login");
                            }
                          }}
                        >
                          Stake Now
                        </button>
                      </td>
                    </tr>
                  );
                }
              })}
            {!loader && list && list.length == 0 && <tr>No record</tr>}
            {loader && <tr>Loading...</tr>}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default StakingList;
