// import package
import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import lodash from "lodash";
import $ from "jquery";
// import context
import SocketContext from "../Context/SocketContext";
import NumberFormat from "react-number-format";
// import action
import {
  getPairList,
  setPairList,
  setTradePair,
  getMarketPrice,
  setMarketPrice,
} from "../../actions/spotTradeAction";
import {
  getAssetByCurrency,
  setUserFirstCurrency,
  setUserSecondCurrency,
} from "../../actions/walletAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { toFixed } from "../../lib/roundOf";
import { pad } from "crypto-js";
import { getPriceConversion } from "../../actions/commonAction";

const MarketPrice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tikerRoot } = useParams();
  const socketContext = useContext(SocketContext);

  // state
  const [pairLists, setPairLists] = useState([]);
  const [onecryptotoinr, setonecryptotoinr] = useState([]);

  const [inrconvertprice, setinrconvertprice] = useState("");
  const [searchclass, setsearchclass] = useState("");

  const [oldMarketPrice, setOldmarketPrice] = useState(0);

  // redux-state
  const tickerData = useSelector((state) => state.marketPrice);
  const priceConversion = useSelector((state) => state.priceConversion);
  const [OriginalpairLists, setOriginalPairList] = useState([]);

  const pairData = useSelector((state) => state.tradePair);
  const pairListData = useSelector((state) => state.pairList);

  // function
  const fetchAssetByCurrency = async (spotPairId, type) => {
    try {
      const { status, loading, error, result } = await getAssetByCurrency(
        spotPairId
      );
      if (status == "success") {
        if (type == "firstCurrency") {
          setUserFirstCurrency(result, dispatch);
        } else if (type == "secondCurrency") {
          setUserSecondCurrency(result, dispatch);
        }
      } else {
      }
    } catch (err) {}
  };

  const fetchPairList = async () => {
    try {
      const { status, loading, result } = await getPairList();
      if (status == "success") {
        setPairList(result, dispatch);
        if (result && result.length > 0) {
          if (isEmpty(tikerRoot)) {
            let pair = `${result[0].firstCurrencySymbol}_${result[0].secondCurrencySymbol}`;
            history.push("/spot/" + pair);
            await fetchAssetByCurrency(
              result[0].firstCurrencyId,
              "firstCurrency"
            );
            await fetchAssetByCurrency(
              result[0].secondCurrencyId,
              "secondCurrency"
            );
            await setMarketPrice(result[0], dispatch);
            await setTradePair(result[0], dispatch);
            // getMarketPrice(result[0]._id, dispatch);
          } else {
            let currency = tikerRoot.split("_");
            let pairDetail = result.find(
              (el) =>
                el.firstCurrencySymbol == currency[0] &&
                el.secondCurrencySymbol == currency[1]
            );
            await fetchAssetByCurrency(
              pairDetail.firstCurrencyId,
              "firstCurrency"
            );
            await fetchAssetByCurrency(
              pairDetail.secondCurrencyId,
              "secondCurrency"
            );

            // getMarketPrice(pairDetail._id, dispatch);
            await setMarketPrice(pairDetail, dispatch);
            await setTradePair(pairDetail, dispatch);
          }
        }
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (!isEmpty(pairData)) {
      // socket
      socketContext.socket.on("marketPrice", (result) => {
        if (result.pairId == pairData.pairId) {
          let pairList = [];

          pairList.push({
            ...result.data,
            ...{
              markPrice: result.data.markPrice,
              change: result.data.change,
              // "volume":  result.volume
            },
          });

          setMarketPrice(pairList[0], dispatch);
        }
      });
    }
    return () => {
      socketContext.socket.off("marketPrice");
    };
  }, [pairData]);

  useEffect(() => {
    // fetchPairList();
    return () => {
      socketContext.socket.off("marketPrice");
      socketContext.socket.off("orderBook");
      socketContext.socket.off("recentTrade");
    };
  }, []);

  // console.log("pairDatapairDatapairData",pairData)
  // let price = priceConversion.find((el=>el.baseSymbol == ))
  return (
    <div className="header-overview">
      <div className="selectCoinType">
        <img
          src={pairData && pairData.firstCurrencyImage}
          alt=""
          className="img-fluid"
        />
        <div className="headerOverviewStatus">
          {pairData && pairData.firstCurrencySymbol}/
          {pairData && pairData.secondCurrencySymbol}
        </div>
        <div className="hoPriceConversion">
          <h3
            className={
              oldMarketPrice
                ? oldMarketPrice < tickerData.markPrice
                  ? "tradeGreenText"
                  : "tradeRedText"
                : "tradeGreenText"
            }
          >
            <NumberFormat
              thousandSeparator={true}
              displayType={"text"}
              className="foo"
              value={toFixed(tickerData.markPrice, pairData.secondFloatDigit)}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
          </h3>
        </div>
      </div>
      <div className="headerOverviewGroup">
        <div className="headerOverviewStatus">
          <h5>
            <small>24h Change</small>
            <span
              className={
                tickerData.change >= 0 ? "tradeGreenText" : "tradeRedText"
              }
            >
              {toFixed(tickerData.changePrice, 2)} (
              {toFixed(tickerData.change, 2)}%)
            </span>
          </h5>
        </div>
        <div className="headerOverviewStatus">
          <h5 className="">
            <small>24h High</small>
            <NumberFormat
              thousandSeparator={true}
              displayType={"text"}
              className="foo"
              value={toFixed(tickerData.high, pairData.secondFloatDigit)}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
          </h5>
        </div>
        <div className="headerOverviewStatus">
          <h5>
            <small>24h Low</small>
            <NumberFormat
              thousandSeparator={true}
              displayType={"text"}
              className="foo"
              value={toFixed(tickerData.low, pairData.secondFloatDigit)}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
          </h5>
        </div>
        <div className="headerOverviewStatus">
          <h5>
            <small>24h Vol({pairData && pairData.firstCurrencySymbol})</small>
            <NumberFormat
              thousandSeparator={true}
              displayType={"text"}
              className="foo"
              value={toFixed(tickerData.firstVolume, pairData.secondFloatDigit)}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
          </h5>
        </div>

        <div className="headerOverviewStatus">
          <h5>
            <small>24h Vol({pairData && pairData.secondCurrencySymbol})</small>
            <NumberFormat
              thousandSeparator={true}
              displayType={"text"}
              className="foo"
              value={toFixed(
                tickerData.secondVolume,
                pairData.secondFloatDigit
              )}
              renderText={(value, props) => <div {...props}>{value}</div>}
            />
          </h5>
        </div>
      </div>
    </div>
  );
};

export default MarketPrice;
