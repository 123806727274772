// import package
import React, { useEffect, useState } from 'react';

// import component
import CanvasChart from '../CanvasChart';

// import action
import { getDashBal } from '../../actions/stakingAction';

const initialFormValue = [{
    'label': 'currency',
    'y': 100,
    'color': "grey",
    'toolTipContent': "Asset is zero balance"
}]

const BalanceChart = () => {

    // state
    const [assetList, setAssetList] = useState([])
    const [dataPts, setDataPts] = useState(initialFormValue)
    const [defaultChart, setDefaultChart] = useState(true)

    // function
    const fetchBalance = async () => {
        try {
            const { status, loading, result } = await getDashBal();
            if (status == 'success') {
                let arr = [], isDefault = true;
                if (result && result.length > 0) {
                    result.map((item, index) => {
                        if ((item.amount) > 0) {
                            arr.push({
                                'label': item.coin,
                                'y': item.amount,
                                'color': item.colorCode,
                            })
                            isDefault = false
                        }
                    })

                    if (!isDefault) {
                        setDataPts(arr)
                    }
                    setAssetList(result)
                    setDefaultChart(isDefault)
                }
            }
        } catch (err) { }
    }

    useEffect(() => {
        fetchBalance()
    }, [])

    return (
        <div className="balance_details_right">
            <div className="chartDash">
                <CanvasChart
                    chartType={"doughnut"}
                    dataPts={dataPts}
                    defaultChart={defaultChart}
                />
            </div>
            <div className="chartLabel">
                <ul>
                    {
                        assetList && assetList.length > 0 && assetList.map((item, key) => {
                            return (
                                <li>
                                    <label><i className="fas fa-square-full" style={{ color: item.colorCode }}></i> {item.coin}</label>
                                    <span>{item.amount}</span>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}


export default BalanceChart;