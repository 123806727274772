// import package
import React, { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux'

// import component
import CancelOrder from './CancelOrder'

// import action
import { getOrderList } from '../../actions/stakingAction'

const OrderList = () => {
    const dispatch = useDispatch();

    // redux
    const { loader, data } = useSelector(state => state.stakeOrder);

    useEffect(() => {
        getOrderList(dispatch)
    }, [])

    return (
        <div className="dashboard_box">
            <h5 className="dash_subtitle">Current Subscription</h5>
            <div className="stakingSubscription">
                <Scrollbars style={{ width: "100%", height: 175 }}>
                    <table className="table table-striped .ml-1">
                        <thead>
                            <tr>
                                <th>Coin</th>
                                <th>APY</th>
                                <th>Type</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                !loader && data && data.length > 0 && data.map((item, key) => {
                                    console.log(item, 'itemitem')
                                    let typestaking=0;
                                    if(item.type=="fixed"){
                                        typestaking="Locked"
                                    }else{
                                        typestaking="Fixed"
                                    }
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <div className="csLeft">
                                                    <h3>
                                                        <img src={item.image} alt="" className="img-fluid" /> {item.name}</h3>
                                                    <p><span>{item.createdAt}</span></p>
                                                </div>
                                            </td>
                                            <td>
                                                  <p><span className="textDepositGreen">{item.APY}%</span></p>
                                            </td>
                                            <td> 
                                                <p><span>{typestaking}</span></p>
                                            </td>
                                            <td>
                                                <div className="csRight">
                                                <h4>{item.amount} {item.coin}</h4>
                                                {
                                                   typestaking=="Fixed" &&item.status == 'active' && <CancelOrder orderData={item} />
                                                }

                                            </div>
                                            </td>
                                        </tr>

                                        // <li key={key}>
                                        //     <div className="csLeft">
                                        //         <h3>
                                        //             <img src={item.image} alt="" className="img-fluid" /> {item.name}</h3>
                                        //             <p><span>{item.createdAt}</span></p>
                                        //     </div>
                                        //      <p><span className="textDepositGreen">{item.APY}%</span></p>
                                        //      <p><span>{"("+item.type+")"}</span></p>
                                        //     <div className="csRight">   
                                        //         <h4>{item.amount} {item.coin}</h4>
                                        //         {
                                        //             item.status == 'active' && <CancelOrder orderData={item} />
                                        //         }

                                        //     </div>
                                        // </li>
                                    )
                                })
                            }
                            {
                                !loader && data && data.length == 0 && <li>No record</li>
                            }
                            {
                                loader && <li>Loading</li>
                            }

                        </tbody>
                    </table>
                </Scrollbars>
            </div>
        </div>
    )
}

export default OrderList;