// import package
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { dateTimeFormat } from '../../lib/dateTimeHelper';
// import action
import {
  setBankForm,
  setPrimaryBank,
  deleteBankDetail,
  getrefferlist,
} from "../../actions/users";

// import lib
import { toastAlert } from "../../lib/toastAlert";

const ReffralDetail = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const [selectedbankfordelete, setselectedbank] = useState({});
  const [result, setResult] = useState();
  // redux-state
  // const { result } = useSelector(state => state.bankDetail);

  // function
  const ListReffer = async () => {
    try {
      const { status, loading, result } = await getrefferlist();
      if (status == "success") {
        console.log("Result123", result);
        setResult(result);
      }
    } catch (err) {}
  };
  useEffect(() => {
    ListReffer();
  }, []);

  return (
    <>
      <div className="table-responsive">
        <h5>Refferal List</h5>
        <table className="table dash_table">
          <thead>
        
            <th>{t("EMAIL_PHONE")}</th>
            <th>Date</th>
          </thead>
          <tbody>
            {result &&
              result.length > 0 &&
              result.map((item, key) => {
                return (
                  <tr>
             
                    <td>{item.email}</td>
                    <td>{dateTimeFormat(item.createdAt, 'YYYY-MM-DD HH:mm')}</td>
          
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ReffralDetail;
