import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// @material-ui/core components
import { Link } from "react-router-dom";
import Header from "components/Header/Header.js";
import Checkbox from "rc-checkbox";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Switch from "@material-ui/core/Switch";
import { Scrollbars } from "react-custom-scrollbars";
import DataTable from "react-data-table-component";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
// import components

import Staking from "../components/Staking";

const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const marks = [
  {
    value: 0,
    label: "1%",
  },
  {
    value: 15,
    label: "15%",
  },
  {
    value: 30,
    label: "30%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 65,
    label: "65%",
  },
  {
    value: 80,
    label: "80%",
  },
  {
    value: 100,
    label: "100%",
  },
];

function valuetext(value) {
  return `${value}%`;
}

// Account Approval Table
const stakingHistory = [
  {
    date: "11-05-2021 15:15",
    crypto: "Bitcoin",
    amount: "0.00215487 BTC",
    apy: "7.25%",
    interestEarned: "0.00000254 BTC",
    status: <div className="textStatusGreen">Success</div>,
  },
  {
    date: "11-05-2021 15:15",
    crypto: "Ethereum",
    amount: "0.00215487 BTC",
    apy: "7.25%",
    interestEarned: "0.00000254 BTC",
    status: <div className="textStatusGreen">Success</div>,
  },
  {
    date: "11-05-2021 15:15",
    crypto: "Ripple",
    amount: "0.00215487 BTC",
    apy: "7.25%",
    interestEarned: "0.00000254 BTC",
    status: <div className="textStatusOrange">Redeemed</div>,
  },
  {
    date: "11-05-2021 15:15",
    crypto: "Tether Coin",
    amount: "0.00215487 BTC",
    apy: "7.25%",
    interestEarned: "0.00000254 BTC",
    status: <div className="textStatusOrange">Redeemed</div>,
  },
  {
    date: "11-05-2021 15:15",
    crypto: "Ripple",
    amount: "0.00215487 BTC",
    apy: "7.25%",
    interestEarned: "0.00000254 BTC",
    status: <div className="textStatusGreen">Success</div>,
  },
];
const stakingHistoryColumns = [
  {
    name: "Date",
    selector: "date",
    sortable: false,
  },
  {
    name: "Crypto",
    selector: "crypto",
    sortable: false,
  },
  {
    name: "Amount",
    selector: "amount",
    sortable: false,
  },
  {
    name: "APY",
    selector: "apy",
    sortable: false,
  },
  {
    name: "Interest Earned",
    selector: "interestEarned",
    sortable: false,
  },
  {
    name: "Status",
    selector: "status",
    sortable: false,
  },
];

export default function StakingPage(props) {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const { ...rest } = props;
 // redux
 const theme = useSelector(state => state.theme)
  return (
    <div className="dashboard_container page_wrap">
      {/* <div className="modal fade" id="stakingModal">
        <div className="modal-dialog modal-dialog-centered modal-lg modalCustomSize">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title mt-0">Transfer Bitcoin</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <GridContainer>
                <GridItem sm={12} md={7}>
                  <div className="stakePopDetails contact_form settingsSelect">
                    <div className="flexText">
                      <label>Wallet Balance </label>
                      <h4>0.0521458798 BTC</h4>
                    </div>
                    <div className="form-group">
                      <label class="flexLabel">
                        <span>Interested Amount to Stake</span>
                        <a href="#">Deposit</a>
                      </label>
                      <Slider
                        defaultValue={20}
                        getAriaValueText={valuetext}
                        aria-labelledby="discrete-slider-custom"
                        step={10}
                        valueLabelDisplay="auto"
                        marks={marks}
                      />
                    </div>
                    <div className="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="btnType1">Stake Amount</span>
                        </div>
                        <input
                          type="text"
                          class="form-control text-right borderZero"
                          value="0.00058780"
                        />
                        <div class="input-group-append">
                          <span class="btnType1">BTC</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <div className="form-check">
                        <Checkbox name="stakeTerms" />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          I have read the <a href="#">terms & conditions</a>
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        className="btn btn-primary text-uppercase w-100"
                        data-toggle="modal"
                        data-target="#stakingModal"
                      >
                        Transfer Confirm
                      </button>
                    </div>
                  </div>
                </GridItem>
                <GridItem sm={12} md={5}>
                  <div className="stakeDetailsRight">
                    <h4>Staking Details</h4>
                    <ul>
                      <li>
                        <label>7-Day APY</label>
                        <span className="textDepositGreen">4.25%</span>
                      </li>
                      <li>
                        <label>Flexible Interest Per Thousand</label>
                        <span>0.00000520 BTC</span>
                      </li>
                      <li>
                        <label>Maximum subscription</label>
                        <span>5.55 BTC</span>
                      </li>
                      <li>
                        <label>Value date</label>
                        <span>24-05-2021 15:15</span>
                      </li>
                    </ul>
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div> */}
      <ScrollToTopOnMount />
      <div className="dashboardMain">
        <div className="dashboardRight afterLoginHeader">
          <Header
            className="header"
            color="transparent"
            routes={dashboardRoutes}
            brand={
              theme == 'dark' ? <img src={require("../assets/images/logo_white.svg")} alt="logo" className="img-fluid" /> : <img src={require("../assets/images/logo.svg")} alt="logo" className="img-fluid" />
            }
            rightLinks={<HeaderLinksAfterlogin />}
            fixed
            changeColorOnScroll={{
              height: 20,
              color: "dark",
            }}
            {...rest}
          />
          <div className="dashboardContent userPages">
            <div className="container">
              <GridContainer>
                <GridItem xs={12} sm={12} md={5} lg={5}>
                  <h3 className="dash_title">Staking</h3>
                </GridItem>
                {/* <GridItem xs={12} sm={12} md={7} lg={7}>
                    <ul className="profile_dash">
                     <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                     <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                   </ul>
                  </GridItem> */}
              </GridContainer>
              <Staking />

             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
