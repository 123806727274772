// import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { moment } from 'moment';
import Moment from "react-moment";

// @material-ui/core components
import { Link, useParams, useHistory } from "react-router-dom";
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTooltip from "react-tooltip";
import Switch from "@material-ui/core/Switch";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { checkDecimal } from "../../lib/roundOf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import action
import { getKycDetail, setUserKyc } from "../../actions/userKyc";
import { getAssetData } from "../../actions/walletAction";
import { getCurrency } from "../../actions/commonAction";
import { getP2PPairList, postTrade } from "../../actions/p2paction";

// import lib
// import validation from "./validation";
import isEmpty from "lib/isEmpty";
import { toastAlert } from "../../lib/toastAlert";
import { SettingsBackupRestoreOutlined } from "@material-ui/icons";
import validation from "./validation";
import config from "../../config";
var unique = [];
const dashboardRoutes = [];

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const initialFormValue = {
  currencyselected: "INR",
  posttocurrency: "USDT",
  postprefcurrency: [],
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: 0,
  paymode: "",
};

export default function PostAd(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userBalance, setUserBalance] = useState(0);
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [secondcurreny, setsecondcurreny] = useState([]);
  const [balancedetails, setBalancedetails] = useState([]);
  const [spotpairsdata, setSpotpairsdata] = useState([]);
  const [curmarketprice, setCurmarketprice] = useState(0);
  const [posttradeprice, setPosttradeprice] = useState(0);
  const [userId, setUserId] = useState("");
  const [upiPrimary, setUpiPrimary] = useState("");
  const [bankPrimary, setBankPrimary] = useState("");
  const [qrPrimary, setQRPrimary] = useState("");
  const [gpay, setGpay] = useState("");
  const [bankname, setBankname] = useState("");
  const [ifsc, setIFSC] = useState("");
  const [accno, setAccno] = useState("");
  const [Upi, setUpiId] = useState("");
  const [payment, setPayment] = useState("");
  const [noBank, setNoBank] = useState(true);
  const [fee, setFee] = useState("");
  var sorc = "";

  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    postminlimit,
    postmaxlimit,
    posttooptions,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    paymode,
  } = formValue;

  // const [state, setState] = React.useState({
  //   checkedA: true,
  //   checkedB: true,
  // });

  // redux-state
  const userKyc = useSelector((state) => state.userKyc);
  const walletData = useSelector((state) => state.wallet);
  const currencyData = useSelector((state) => state.currency);
  const upiData = useSelector((state) => state.upiDetail);
  const bankData = useSelector((state) => state.bankDetail);
  const qrData = useSelector((state) => state.qrCodeDetail);

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  const { ...rest } = props;

  // Handle change for add the values
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "postminlimit" || name == "quantity" || name == "price") {
      if (!/^\d*\.?\d*$/.test(value)) {
        return;
      }
      if (name == "quantity") {
        if (checkDecimal(value, 8)) {
          return;
        }
      }
      if (name == "price") {
        if (checkDecimal(value, 2)) {
          return;
        }
      }
      if (name == "postminlimit") {
        if (checkDecimal(value, 2)) {
          return;
        }
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };
    console.log(
      "ffffffffffffffffffffffffffffffffffffffffff",
      value,
      formData,
      formValue
    );
    setFormValue(formData);
    // setValidateError(validation(formData));
    if (name == "quantity") {
      var totPrice = value * price;
      setPosttradeprice(totPrice);
    }
  };
  // Currency change
  const curencyChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    var index = balancedetails.findIndex((x) => x.currencySymbol === value);
    if (index != -1) {
      setUserBalance(balancedetails[index].p2pbalance);
    }
    var pair = posttocurrency + value;

    if (e.target.name == "posttocurrency") {
      let sec = [];
      setsecondcurreny(sec);
      // let sec=[];
      {
        spotpairsdata.length > 0 &&
          spotpairsdata.map((item, i) => {
            if (item.first_currency == e.target.value) {
              sec.push(item);
              secondcurreny.push(item);
            }
          });
      }
    }
    var tempprice = 0;
    var pair = posttocurrency + value;

    {
      spotpairsdata.length > 0 &&
        spotpairsdata.map((item, i) => {
          if (item.tiker_root === pair) {
            setCurmarketprice(item.index_price);

            var totPrice = quantity * item.index_price;
            setFee(item.transactionfee);
            // setPosttradeprice(totPrice);
          }
        });
    }
  };
  const handleposttocurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    var pair = currencyselected + value;
    var tempprice = 0;

    var curindex = spotpairsdata.findIndex((x) => x.tiker_root === pair);
    if (curindex != -1) {
      tempprice = spotpairsdata[curindex].index_price;
      setCurmarketprice(tempprice);
    }
    var totPrice = quantity * curmarketprice;
    setPosttradeprice(totPrice);
  };

  //

  const handlepostprefcurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    if (value == "UPI") {
      setGpay("");
      if (upiPrimary) {
        setPayment("UPI ID : " + upiPrimary);
        setNoBank(false);
      } else {
        setPayment("No UPI Details Found");
        setNoBank(true);
      }
    }
    if (value == "Bank") {
      setGpay("");
      if (bankPrimary.bankName) {
        setPayment(
          "Bank: " +
            bankPrimary.bankName +
            " -  AccountNo: " +
            bankPrimary.accountNo +
            " - IFSCCode: " +
            bankPrimary.bankcode
        );
        setNoBank(false);
      } else {
        setNoBank(true);
        setPayment("No Bank Details Found");
      }
    }
    if (value == "Gpay") {
      if (qrPrimary.frontImage) {
        sorc = config.API_URL + "/images/qr/" + qrPrimary.frontImage;
        setPayment("");
        setNoBank(false);
        setGpay(sorc);
      } else {
        setNoBank(true);
        setPayment("No Gpay Details Found");
      }
    }
  };

  const onChangeCheckbox = (e) => {
    let formData = { ...formValue, ...{ [e.target.id]: e.target.checked } };
    setFormValue(formData);
  };

  // const postSubmit = (e)=>{
  const postSubmit = async (e) => {
    e.preventDefault();
    try {
      // alert(startDate)
      let reqData = {
        userId: userId,
        fromcurrency: posttocurrency,
        tocurrency: currencyselected,
        posttradeprice: posttradeprice,
        type: BuyorSell,
        minlimit: postminlimit,
        maxlimit: posttradeprice,
        quantity: quantity,
        postcheckboxaccept: postcheckboxaccept,
        postprefcurrency: postprefcurrency,
        startDate: startDate,
        price: price,
        paymode: payment && payment ? payment : qrPrimary.frontImage,
        noBank: noBank,
      };
      console.log("Reqqqqqq---", reqData);
      const validateError = await validation(reqData);
      console.log("Reqqqqqq---", validateError);
      if (!isEmpty(validateError)) {
        setValidateError(validateError);
      } else {
        setValidateError("");
        window.$("#confirm_post").modal("show");
      }
    } catch (err) {
      console.log("errrrr", err);
    }
  };

  const confirmPost = async (e) => {
    e.preventDefault();
    window.$("#confirm_post").modal("hide");

    let reqData = {
      userId: userId,
      fromcurrency: posttocurrency,
      tocurrency: currencyselected,
      posttradeprice: posttradeprice,
      type: BuyorSell,
      minlimit: postminlimit,
      maxlimit: posttradeprice,
      quantity: quantity,
      price: price,
      postcheckboxaccept: postcheckboxaccept,
      postprefcurrency: postprefcurrency,
      startDate: startDate,
      paymentDetail: payment ? payment : gpay,
    };
    let { status, loading, message, error } = await postTrade(
      reqData,
      dispatch
    );
    if (status == true) {
      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      //history.push('/p2p')

      window.location.href = "/p2p";
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  // Initial values getting function
  const fetchData = async () => {
    try {
      const { status, loading } = await getKycDetail(dispatch);
    } catch (err) {}
  };

  const assetBalance = async () => {
    try {
      const { result, status, loading } = await getAssetData(dispatch);
    } catch (err) {}
  };
  const currencyDetails = async () => {
    try {
      const { result, status, loading } = await getCurrency(dispatch);
    } catch (err) {}
  };
  const calculatePrice = async (val) => {
    var tradeprice = val * quantity;
    setPosttradeprice(tradeprice);
  };
  const p2pSpotPair = async () => {
    try {
      const { result, status, loading } = await getP2PPairList(dispatch);

      if (result) {
        setSpotpairsdata(result);
        var tempdata = result;
        var arr = [];
        var pair = posttocurrency + currencyselected;

        var indexonchcange = spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );

        {
          result.length > 0 &&
            result.map((item, i) => {
              if (item.tiker_root === pair) {
                setCurmarketprice(item.index_price);
                arr.push({
                  second_currency: item.second_currency,
                });
              }
            });
        }
        //     let a = ["1", "1", "2", "3", "3", "1"];
        let unique = arr.filter((item, i, ar) => ar.indexOf(item) === i);
        console.log("unique---", unique);
      }
    } catch (err) {}
  };

  useEffect(() => {
    // getKycDetail(dispatch)
    if (!isEmpty(userKyc)) {
      fetchData();
      assetBalance();
      currencyDetails();
      p2pSpotPair();
    }
  }, []);

  useEffect(() => {
    if (userKyc && userKyc) {
      setUserId(userKyc.userId);
      if (
        (userKyc.addressProof &&
          userKyc.addressProof.status != "" &&
          userKyc.addressProof.status != "approved") ||
        (userKyc.idProof &&
          userKyc.idProof.status != "" &&
          userKyc.idProof.status != "approved")
      ) {
        toastAlert("success", "Please submit your kyc...");
        history.push("/kyc");
      }
    }
    if (walletData && walletData.length > 0) {
      setBalancedetails(walletData);
      var tempdata = walletData;
      var ind = tempdata.findIndex((x) => x.currencySymbol === posttocurrency);
      if (ind != -1) {
        console.log("USDT data---", tempdata[ind].p2pbalance);

        setUsdtBalance(tempdata[ind].p2pbalance);
      }
      var index = tempdata.findIndex(
        (x) => x.currencySymbol === currencyselected
      );
      if (index != -1) {
        // console.log("Wallet data---",tempdata)
        setUserBalance(tempdata[index].p2pbalance);
        // this.setState({ userbalance: tempdata[index].balance });
      }
    }

    let findData = spotpairsdata.find(
      (el) => el.second_currency == currencyselected
    );
    setFee(findData && findData.transactionfee);
    if (spotpairsdata && spotpairsdata.length > 0) {
      var array = [];
      spotpairsdata.map((item, key) => {
        array.push(item.first_currency);
      });
      unique = array.filter((v, i, a) => a.indexOf(v) === i);
      // console.log("unique---", array);

      console.log("unique---", unique);
    }
    if (currencyData && currencyData.length > 0) {
    }
    if (upiData && upiData.result && upiData.result.length > 0) {
      upiData.result.map((item, key) => {
        console.log("itttttttttttttt===", item);
        if (item.isPrimary == true) {
          setUpiPrimary(item.upiId);
        }
      });
    }
    if (qrData && qrData.result) {
      qrData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setQRPrimary(item);
        }
      });
    }
    if (bankData && bankData.result) {
      bankData.result.map((item, key) => {
        if (item.isPrimary == true) {
          setBankPrimary(item);
        }
      });
    }
    if (spotpairsdata && spotpairsdata.length > 0) {
      setSpotpairsdata(spotpairsdata);
      var tempdata = spotpairsdata;
      var pair = currencyselected + posttocurrency;
      var indexonchcange = spotpairsdata.findIndex(
        (x) => x.tiker_root === pair
      );
      if (indexonchcange != -1) {
        var onchbaseprice = spotpairsdata[indexonchcange].index_price;
        setCurmarketprice(onchbaseprice);
      }
    }
    let sec = [];
    setsecondcurreny(sec);

    // let sec=[];
    {
      spotpairsdata.length > 0 &&
        spotpairsdata.map((item, i) => {
          if (item.first_currency == posttocurrency) {
            sec.push(item);
            secondcurreny.push(item);
          }
        });
    }

    // setValidateError(validation(formValue))
  }, [
    userKyc,
    walletData,
    spotpairsdata,
    currencyData,
    upiData,
    bankData,
    qrData,
  ]);

  console.log("upiDataupiDataupiData---", upiPrimary);
  //console.log("upiDataupiDataupiData---",sorc)

  // redux
  const theme = useSelector((state) => state.theme);

  return (
    <div className="dashboardMain">
      <div className="dashboardRight afterLoginHeader">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            theme == "dark" ? (
              <img
                src={require("../../assets/images/logo_white.svg")}
                alt="logo"
                className="img-fluid"
              />
            ) : (
              <img
                src={require("../../assets/images/logo.svg")}
                alt="logo"
                className="img-fluid"
              />
            )
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />
        <div className="dashboardContent userPages peer2peer_section">
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}>
                <h3 className="dash_title">
                  Peer-to-Peer{" "}
                  <span>
                    <i class="fas fa-chevron-right arrow_key_titlw"></i>{" "}
                  </span>{" "}
                  <span className="color_gol-sd">Post My Ad</span>
                </h3>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="dashboard_box t2patid">
                  <div className="end_content mar---butm">
                    {" "}
                    <p className="color_whit_7 with_22_2  inputabowlabel">
                      <span className="spna_ifd">Wallet:</span>
                      {userBalance.toFixed(2)} {currencyselected}/
                      {usdtBalance.toFixed(8)}USDT
                    </p>
                    {BuyorSell == "Sell" && (
                      <p className="color_whit_7 with_22_2  inputabowlabel">
                        <span className="spna_ifd">Fee:</span>
                        {fee + "%"}
                      </p>
                    )}
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <div className="contact_form mb-0 w100_form_p2p pa125sd">
                        {/* <form className="contact_form mb-0 w100_form_p2p pa125sd"> */}
                        <div className="settingsSelect clas_Newselect clas_Newselect124">
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78">
                                  I want to{" "}
                                </label>
                                <Select
                                  name="BuyorSell"
                                  value={BuyorSell}
                                  onChange={handleChange}
                                >
                                  <MenuItem value="Buy">Buy</MenuItem>
                                  <MenuItem value="Sell">Sell</MenuItem>
                                </Select>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78">
                                  Crypto currency
                                </label>
                                <Select
                                  name="posttocurrency"
                                  value={posttocurrency}
                                  onChange={curencyChange}
                                >
                                  {unique &&
                                    unique.length > 0 &&
                                    unique.map((item, key) => {
                                      //   if (item.type == 'fiat') {
                                      return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      );
                                      // }
                                    })}
                                </Select>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78">
                                  For Fiat currency
                                </label>
                                <Select
                                  name="currencyselected"
                                  value={currencyselected}
                                  onChange={curencyChange}
                                >
                                  {secondcurreny &&
                                    secondcurreny.length > 0 &&
                                    secondcurreny.map((item, key) => {
                                      // if (item.type == 'fiat') {
                                      return (
                                        <MenuItem value={item.second_currency}>
                                          {item.second_currency}
                                        </MenuItem>
                                      );
                                      // }
                                    })}
                                </Select>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23 flex-column ">
                                <div className="d-flex justify-content-center align-items-center w-100">
                                  <label className="label_color_78">
                                    Quantity
                                  </label>
                                  <div class="input-group ">
                                    <input
                                      type="number"
                                      name="quantity"
                                      class="form-control"
                                      value={quantity}
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        className="inpor_inside_buttons"
                                      >
                                        USDT
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {validateError.quantity && (
                                  <p className="error-message">
                                    {validateError.quantity}
                                  </p>
                                )}
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={4}
                              className="paddin_gri_po mt-151_15"
                            >
                              {/* <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78 label_widthsdf">Price</label>
                                <div className="flot_fixe_ins width_input">
                                <input type="text" className="form-control" value={posttradeprice} readOnly={true}/>
                                <div class="input-group-append">
                                <button type="button" className="inpor_inside_buttons">{currencyselected}</button>
                              </div>
                                </div>
                                
                              </div> */}
                              {/* <div className="form-group d-flex_p2p d-flex_p2p_23">
                                <label className="label_color_78">Price</label>
                                <div class="input-group ">
                               
                                <input type="number" name="Price" class="form-control" value={posttradeprice}/>
                              <div class="input-group-append">
                                <button type="button" className="inpor_inside_buttons">{currencyselected}</button>
                              </div>
                              {validateError.quantity && <p className="error-message">{(validateError.quantity)}</p>} 
                            </div>
                                
                              </div> */}
                              <div className="form-group d-flex_p2p d-flex_p2p_23 flex-column ">
                                <div className="d-flex justify-content-center align-items-center w-100">
                                  <label className="label_color_78">
                                    Per {posttocurrency} Price
                                  </label>
                                  <div class="input-group ">
                                    <input
                                      type="text"
                                      name="price"
                                      class="form-control"
                                      value={price}
                                      onBlur={(e) =>
                                        calculatePrice(e.target.value)
                                      }
                                      onChange={handleChange}
                                    />
                                    <div class="input-group-append">
                                      <button
                                        type="button"
                                        className="inpor_inside_buttons"
                                      >
                                        {currencyselected}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                {validateError.price && (
                                  <p className="error-message">
                                    {validateError.price}
                                  </p>
                                )}
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={8}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23 whithAutft">
                                <label className="label_color_78 ">
                                  per {posttocurrency}
                                </label>
                                <div className="bitcoin_value_box">
                                  <h3>
                                    <span>Current Market Price </span>1{" "}
                                    {posttocurrency} = {curmarketprice}{" "}
                                    {currencyselected}
                                  </h3>
                                </div>
                              </div>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={7}
                              className="mt-151_15"
                            >
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={12} lg={6}>
                                  <div className="form-group d-flex_p2p d-flex_p2p_23 flort_lesttd flex-column w-100 mb-2">
                                    <div className="d-flex justify-content-center align-items-center w-100 mb-2">
                                      <label className="label_color_78">
                                        Price Limit From{" "}
                                      </label>
                                      <div class="input-group ">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="postminlimit"
                                          value={postminlimit}
                                          onChange={handleChange}
                                        />
                                        <div class="input-group-append">
                                          <button
                                            type="button"
                                            className="inpor_inside_buttons"
                                          >
                                            {currencyselected}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    {validateError.postminlimit && (
                                      <p className="error-message">
                                        {validateError.postminlimit}
                                      </p>
                                    )}
                                  </div>
                                </GridItem>

                                <GridItem xs={12} sm={12} md={12} lg={6}>
                                  <div className="form-group d-flex_p2p d-flex_p2p_23 flort_lesttd w-100 mb-2">
                                    <div className="d-flex justify-content-center align-items-center w-100 mb-2">
                                      <label className="label_color_78">
                                        To
                                      </label>
                                      <div class="input-group ">
                                        <input
                                          type="text"
                                          class="form-control"
                                          name="postmaxlimit"
                                          value={posttradeprice}
                                          readOnly
                                        />
                                        <div class="input-group-append">
                                          <button
                                            type="button"
                                            className="inpor_inside_buttons"
                                          >
                                            {currencyselected}
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    {validateError.postmaxlimit && (
                                      <p className="error-message">
                                        {validateError.postmaxlimit}
                                      </p>
                                    )}
                                  </div>
                                </GridItem>
                              </GridContainer>
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={5}
                              className="mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23 flort_lesttd  w100_form_p2p date-width">
                                <label
                                  className="primary_label"
                                  htmlFor="minimumbid"
                                >
                                  Show My Post Till
                                </label>
                                <div className="wdth--1001">
                                  <DatePicker
                                    className="form-control primary_inp"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    dateFormat="dd/MM/yyyy h:mm aa"
                                    showTimeSelect
                                  ></DatePicker>
                                  <i class="far fa-calendar-alt"></i>
                                </div>
                              </div>
                              {validateError.startDate && (
                                <p className="error-message">
                                  {validateError.startDate}
                                </p>
                              )}
                            </GridItem>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              lg={12}
                              className="paddin_gri_po mt-151_15"
                            >
                              <div className="form-group d-flex_p2p d-flex_p2p_23 whithAutft select_width">
                                <label className="label_color_78">
                                  Preferred Payments
                                </label>
                                <Select
                                  name="postprefcurrency"
                                  value={postprefcurrency}
                                  onChange={handlepostprefcurrency}
                                >
                                  <MenuItem value="Bank">Bank</MenuItem>
                                  <MenuItem value="UPI">UPI</MenuItem>
                                  <MenuItem value="Gpay">Gpay</MenuItem>
                                </Select>
                              </div>
                              {validateError.postprefcurrency && (
                                <p className="error-message">
                                  {validateError.postprefcurrency}
                                </p>
                              )}
                              {postprefcurrency && postprefcurrency != "" ? (
                                <>
                                  {" "}
                                  <GridItem
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={12}
                                    className="paddin_gri_po mt-151_15"
                                  >
                                    <div className="qr_code_">
                                      {payment && payment ? (
                                        payment
                                      ) : (
                                        <img
                                          src={gpay}
                                          height="100"
                                          width="100"
                                        />
                                      )}
                                      <a href="/profile">Edit</a>
                                    </div>
                                  </GridItem>
                                </>
                              ) : (
                                ""
                              )}
                              {/* {
                                  bankname && bankname !=='' && payment=='Bank'?
                               <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                               <div className="qr_code_">
                               <p><span>Bank Name:</span><span>{bankname}</span></p>
                               <p><span>Account Number:</span><span>{accno}</span></p>
                               <p><span>IFEC Code:</span><span>{ifsc}</span></p>
                              
                               <button  className="btn btn-primary text-uppercase py-2 display_block_mars" >Edit</button>
 
                               </div>
                                </GridItem>
                                : <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                                <div className="qr_code_">
                                  <p><span>{nodetails}</span></p>
                                  </div>
                                  </GridItem>
                                
                                }
                                {
                                  Upi && payment=='UPI'?
                               <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                               <div className="qr_code_">
                              
                               <p><span>UPI Id:</span><span>{Upi}</span></p>
                              
                               <button  className="btn btn-primary text-uppercase py-2 display_block_mars" >Edit</button>
 
                               </div>
                                </GridItem>
                                : <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                                <div className="qr_code_">
                                  <p><span>{nodetails}</span></p>
                                  </div>
                                  </GridItem>
                                
                                }
                                 {
                                  sorc && payment=='Gpay'?
                               <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                               <div className="qr_code_">
                              
                               <img src={sorc} alt="logo" className="img-fluid" />
                              
                               <button  className="btn btn-primary text-uppercase py-2 display_block_mars" >Edit</button>
 
                               </div>
                                </GridItem>
                                : <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                                <div className="qr_code_">
                                  <p><span>{nodetails}</span></p>
                                  </div>
                                  </GridItem>
                                
                                } */}
                            </GridItem>
                            {/* <GridItem xs={12} sm={12} md={6} lg={12} className="paddin_gri_po mt-151_15"> 
                              <div className="qr_code_">
                              <img src={sorc} alt="logo" className="img-fluid" />
                              <div className="">
                              <p><span>Bank Name:</span><span>Raja</span></p>
                              <p><span>Account Number:</span><span>11021555455555</span></p>
                              <p><span>IFEC Code:</span><span>IDBI78895</span></p>
                              <p><span>UPI ID:</span><span>UPI@0900999090</span></p>
                              <button  className="btn btn-primary text-uppercase py-2 display_block_mars" >Edit</button>

                              </div>
                              </div>
                             
                              </GridItem> */}
                            <GridItem
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="paddin_gri_po mt-151_15"
                            >
                              <p className="ckksdyt_box">
                                <input
                                  type="checkbox"
                                  id="postcheckboxaccept"
                                  onChange={onChangeCheckbox}
                                  value={postcheckboxaccept}
                                />
                                I agree to Escrow my crypto funds and accept the{" "}
                                <span>
                                  <a href="/terms">terms</a>
                                </span>{" "}
                                and{" "}
                                <span>
                                  <a href="/privacy-policy">policy</a>
                                </span>
                              </p>
                              {validateError.postcheckboxaccept && (
                                <p className="error-message">
                                  {validateError.postcheckboxaccept}
                                </p>
                              )}

                              {/* <span className="ckksdyt_box">Transaction Fee for Seller : {fee} %</span> */}
                              <button
                                onClick={postSubmit}
                                className="btn btn-primary text-uppercase py-2 display_block_mars"
                              >
                                Submit Post
                              </button>
                            </GridItem>
                          </GridContainer>
                        </div>
                      </div>

                      {/* </form> */}
                    </GridItem>
                  </GridContainer>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="confirm_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_post"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body ">
                <h2>Confirm My Post</h2>
                <img
                  src={require("../../assets/images/postconfirm.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span className="righttext">Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{posttocurrency}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Per Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {price} {currencyselected}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Quantity: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {quantity} {posttocurrency}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Limit:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {postminlimit} {currencyselected} - {posttradeprice}{" "}
                      {currencyselected}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Payment Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{postprefcurrency}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Payment Detail:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      {payment
                        ? payment
                        : gpay && <img src={gpay} height="100" width="100" />}
                    </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Post Show Till:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      <Moment format="DD-MM-YYYY HH:mm">{startDate}</Moment>
                    </span>
                  </div>
                  {/* <div className="detail_text_model">
                                  <span className="righttext">Post ID:</span>
                                </div>
                                <div className="detail_text_model">
                                  <span className="colo_fff">#123456</span>
                                </div> */}
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button onClick={confirmPost} className="button1">
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
