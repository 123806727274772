import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link, useParams, useHistory } from "react-router-dom";

// @material-ui/core components
import Header from "components/Header/Header.js";
import HeaderLinksAfterlogin from "components/Header/HeaderLinksAfterlogin.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ReactTooltip from "react-tooltip";
import Switch from "@material-ui/core/Switch";
import LastLogin from "../Dashboard/LastLogin";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";

// import action
import { getKycDetail, setUserKyc } from "../../actions/userKyc";
import { getAssetData } from "../../actions/walletAction";
import { getCurrency, setCurrencyOption } from "../../actions/commonAction";
import Checkbox from "rc-checkbox";

import {
  buyConfirmP2PTrade,
  buyP2PTrade,
  getMyRecentaddData,
  getP2PPairList,
  updateTrade,
  getBuyAdData,
  getSellAdData,
  getSarchBuyData,
  getSarchSellData,
  updatevacation,
} from "../../actions/p2paction";
// import { getUserSetting } from "../../actions/users";

import userSetting from "reducers/userSetting";
import { getUserSetting } from "actions/users";

const dashboardRoutes = [];
var unique = [];
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  currencyselected: "USDT",
  posttocurrency: "USD",
  postprefcurrency: "Bank",
  postminlimit: "",
  postmaxlimit: "",
  posttooptions: [],
  postcheckboxaccept: false,
  BuyorSell: "Buy",
  quantity: 0,
  price: "",
  postId: "",
  secondamount: "",
};

const initialBuyOrder = {
  buyorderPrice: 0,
  sellerEmail: "",
  paymentTimelimit: "",
  crypto_amount: 0,
  fiat_amount: 0,
  buyOrderId: "",
  buyQuantity: 0,
  buyFiatAmount: 0,
  buytransferMode: "",
  buytoFiatCurrency: "",
  buytoCryptoCurrency: "",
};

export default function P2pBuySell(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [balancedetails, setBalancedetails] = useState([]);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [buyOrderValue, setBuyOrderValue] = useState(initialBuyOrder);
  const [userId, setUserId] = useState("");
  const [userBalance, setUserBalance] = useState(0);
  const [spotpairsdata, setSpotpairsdata] = useState([]);
  const [curmarketprice, setCurmarketprice] = useState(0);
  const [myaddDetails, setMyaddDetails] = useState([]);
  const [validateError, setValidateError] = useState({});
  const [currencyOptionList, setCurrencyOptionList] = useState([]);
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const [cryptocurrency, setCryptocurrency] = useState("USDT");
  const [maxbuy, setMaxbuy] = useState("");
  const [buytocurrency, setBuytocurrency] = useState("INR");
  const [buyads, setBuyads] = useState([]);
  const [sellads, setSellads] = useState([]);
  const [buyAddDetails, setBuyAddDetails] = useState({});
  const [sellAddDetails, setSellAddDetails] = useState({});
  const [fiatAmount, setFiatAmount] = useState(0);
  const [cryptoAmount, setCryptoAmount] = useState(0);
  const [estimateValue, setEstimateValue] = useState(0);
  const [buy_vac, setbuy_vac] = useState(false);
  const [sell_vac, setsell_vac] = useState(false);
  const [convertbalance, setconvertbalance] = useState(0);
  const priceConversion = useSelector((state) => state.priceConversion);

  const {
    buytoFiatCurrency,
    buytoCryptoCurrency,
    buyFiatAmount,
    buytransferMode,
    buyorderPrice,
    sellerEmail,
    paymentTimelimit,
    crypto_amount,
    fiat_amount,
    buyOrderId,
    buyQuantity,
  } = buyOrderValue;

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });
  // const handleChange = (event) => {

  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    console.log(formData, "formDataformData");
  };

  const handleCheckBox_sell = async (e) => {
    const { name, checked } = e.target;
    setsell_vac(checked);
    await Vacationupdate("sell", checked);
  };

  const handleCheckBox_buy = async (e) => {
    const { name, checked } = e.target;
    setbuy_vac(checked);
    await Vacationupdate("buy", checked);
  };

  const { ...rest } = props;

  const {
    currencyselected,
    posttocurrency,
    postprefcurrency,
    postminlimit,
    postmaxlimit,
    posttooptions,
    postcheckboxaccept,
    BuyorSell,
    quantity,
    price,
    postId,
    secondamount,
  } = formValue;

  // redux-state
  const { isAuth } = useSelector((state) => state.auth);
  const walletData = useSelector((state) => state.wallet);
  const currencyData = useSelector((state) => state.currency);
  const authDetails = useSelector((state) => state.auth);
  const userSetting = useSelector((state) => state.userSetting);
  const userKyc = useSelector((state) => state.userKyc);
  console.log(currencyData, "userSettinguserSetting");
  //  Handle on change
  const handleposttocurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    var pair = currencyselected + value;
    var tempprice = 0;
    var curindex = spotpairsdata.findIndex((x) => x.tiker_root === pair);
    console.log("curindex---------", curindex);
    if (curindex != -1) {
      tempprice = spotpairsdata[curindex].index_price;
      // console.log('curmarketprice--------',tempprice);
      setCurmarketprice(tempprice);
    }
    // var totPrice= quantity * curmarketprice;
    // console.log("PricePricePrice",totPrice);
    // setPosttradeprice(totPrice);
  };
  // Initial values getting function
  const fetchData = async () => {
    try {
      const { status, loading } = await getKycDetail(dispatch);
    } catch (err) {}
  };

  const assetBalance = async () => {
    try {
      const { result, status, loading } = await getAssetData(dispatch);
    } catch (err) {}
  };
  const getcurrencyDetails = async () => {
    try {
      const { result, status, loading } = await getCurrency(dispatch);
    } catch (err) {}
  };
  const p2pSpotPair = async () => {
    try {
      const { result, status, loading } = await getP2PPairList(dispatch);
      var array = [];
      if (result) {
        setSpotpairsdata(result);
        array.push();
        var tempdata = result;
        var pair = "USDT" + "USD";
        var indexonchcange = spotpairsdata.findIndex(
          (x) => x.tiker_root === pair
        );
        if (indexonchcange != -1) {
          var onchbaseprice = spotpairsdata[indexonchcange].index_price;
          setCurmarketprice(onchbaseprice);
        }
      }
    } catch (err) {}
  };

  const getMyads = async (user) => {
    try {
      let reqData = {
        curUser: user,
      };
      // return false;
      let { result, loading, message, error } = await getMyRecentaddData(
        reqData
      );
      setMyaddDetails(result);
      // const { result, status, loading } = await getMyaddData(userId)
    } catch (err) {}
  };

  const getbuyads = async (userId, crypto, fiat) => {
    var postdata = {
      userid: userId,
      // limit: 5,
      offset: 0,
      crypto: crypto,
      fc: crypto,
      fiat: fiat,
    };
    let { result, loading, message, error } = await getBuyAdData(postdata);

    if (result) {
      setBuyads(result);
    }
  };

  const getsellads = async (userId, crypto, fiat) => {
    var postdata = {
      userid: userId,
      // limit: 6,
      offset: 0,
      crypto: crypto,
      fc: crypto,
      fiat: fiat,
    };
    let { result, loading, message, error } = await getSellAdData(postdata);
    if (result) {
      setSellads(result);
    }
    // axios.post(url + "tradeapi/getsellads", postdata).then((res) => {
    //   this.setState({ sellads: res.data.tradedata });
    // });
  };
  const searchBuyads = async (searchData) => {
    if (searchData) {
      let { result, loading, message, error } = await getSarchBuyData(
        searchData
      );
      if (result) {
        setValidateError("");
        setBuyads(result);
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "login");
      }
    }
  };

  const searchSellads = async (searchData) => {
    if (searchData) {
      let { result, loading, message, error } = await getSarchSellData(
        searchData
      );
      if (result) {
        setValidateError("");
        setSellads(result);
      } else {
        if (error) {
          setValidateError(error);
        }
        toastAlert("error", message, "login");
      }
    }
  };

  const Vacationupdate = async (type, checked) => {
    var postdata = {
      userid: userId,
      sell_vacation: checked,
      buy_vacation: checked,
      type: type,
    };

    let { result, loading, message, error } = await updatevacation(postdata);
    if (result) {
      toastAlert("success", "Vacation Updated successfully", "login");
    }
    // axios.post(url + "tradeapi/getsellads", postdata).then((res) => {
    //   this.setState({ sellads: res.data.tradedata });
    // });
  };

  useEffect(() => {
    console.log("Length -----", currencyData.length);
    // setCurrencyOptionList(null);
    assetBalance();
    getcurrencyDetails();
    p2pSpotPair();
    getUserSetting();
  }, []);

  useEffect(() => {
    if (userSetting) {
      setbuy_vac(userSetting.buy_vacation);
      setsell_vac(userSetting.sell_vacation);
    }
  }, [userSetting]);

  useEffect(() => {
    setCurrencyOptionList("");
    if (priceConversion && walletData && walletData.length > 0) {
      setBalancedetails(walletData);
      var tempdata = walletData;
      var index = tempdata.findIndex((x) => x.currencySymbol === "USDT");
      if (index != -1) {
        setUserBalance(tempdata[index].p2pbalance);
      }
    }
    if (spotpairsdata && spotpairsdata.length > 0) {
      var array = [];
      spotpairsdata.map((item, key) => {
        array.push(item.first_currency);
      });
      unique = array.filter((v, i, a) => a.indexOf(v) === i);
      console.log("unique---", array);

      console.log("unique---", unique);
    }
    if (spotpairsdata && spotpairsdata.length > 0) {
      setSpotpairsdata(spotpairsdata);
      var tempdata = spotpairsdata;
      var pair = "USDT" + "USD";
      var indexonchcange = spotpairsdata.findIndex(
        (x) => x.tiker_root === pair
      );
      console.log("Index---", indexonchcange);
      if (indexonchcange != -1) {
        var onchbaseprice = spotpairsdata[indexonchcange].index_price;
        var est_value = (onchbaseprice * userBalance).toFixed(2);
        // setEstimateValue(est_value);
      }
    }
    if (authDetails.userId) {
      setUserId(authDetails.userId);
      getMyads(authDetails.userId);
      getbuyads(authDetails.userId);
      getsellads(authDetails.userId);
    }
    console.log(priceConversion, "priceConversion");
    console.log(estimateValue, "estimateValue");

    let firPriceCNV = priceConversion.find(
      (el) => el.baseSymbol == "USDT" && el.convertSymbol == "INR"
    );
    if (firPriceCNV) {
      setconvertbalance(userBalance * firPriceCNV.convertPrice);
    }
    console.log(firPriceCNV, "firPriceCNV");
  }, [walletData, spotpairsdata, authDetails, currencyData]);

  console.log(convertbalance, "convertbalance");

  const updateMyad = async (e) => {
    window.$("#edit_post").modal("show");
    if (e && e != undefined) {
      // setCurrentPost(e)
      let formData = {
        ...formValue,
        ...{
          postprefcurrency: e.transferMode,
          postminlimit: e.minlimit,
          postmaxlimit: e.maxlimit,
          price: e.price,
          postId: e._id,
          currencyselected: e.firstCurrency,
          BuyorSell: e.BuyorSell,
        },
      };
      setFormValue(formData);
    }
  };

  const updatePost = async (e) => {
    e.preventDefault();
    let reqData = {
      postId: postId,
      userId: userId,
      price: price,
      minlimit: postminlimit,
      maxlimit: postmaxlimit,
      postprefcurrency: postprefcurrency,
    };
    let { status, loading, message, error } = await updateTrade(
      reqData,
      dispatch
    );
    if (status == "success") {
      window.$("#edit_post").modal("hide");

      setFormValue(initialFormValue);
      toastAlert("success", message, "login");
      history.push("/p2p");
    } else {
      if (error) {
        setValidateError(error);
      }
      toastAlert("error", message, "login");
    }
  };

  const validPositive = (e) => {
    if (
      new RegExp(`^\\d*(\\.\\d{0,2})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  const handlepostprefcurrency = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
  };

  // Handle nav currency and buy sell changes
  const handlecurrenttab = async (e) => {
    console.log(posttocurrency, "currency change", e);
    setCryptocurrency(e);

    var curindex = currencyDetails.findIndex(
      (x) => x.currencySymbol === cryptocurrency
    );
    if (curindex != -1) {
      setMaxbuy(currencyDetails[curindex].maxbuy);
    }

    getbuyads(userId, e, posttocurrency);
    getsellads(userId, e, posttocurrency);
  };
  // Find ads use filter
  const searchfindAds = async (e) => {
    console.log("tssss");
    e.preventDefault();
    // const fc = cryptocurrency;
    // const sc = posttocurrency;
    // const postprefcurrency= postprefcurrency;
    // const sc_amount = secondamount;
    // let userid = this.props.auth.user.id;
    let searchData = {
      fc: cryptocurrency,
      sc: posttocurrency,
      transferMode: postprefcurrency,
      sc_amount: secondamount,
      userId: userId,
    };
    // var data_find = {
    //   fc: fc,
    //   sc: sc,
    //   adstype: adstype,
    //   fc_amount: fc_amount,
    //   sc_amount: sc_amount,
    //   transfer: postprefcurrency,
    //   userid: userid,
    //   limit: 6,
    //   offset: 0,
    // };

    searchBuyads(searchData);
    searchSellads(searchData);
  };
  // Buy and sell ad
  const buySingleAd = async (e) => {
    if (!isAuth) {
      toastAlert("error", "Please login");
      return;
    }
    if (userKyc && userKyc) {
      console.log("userKyc---", userKyc);

      if (
        (userKyc.addressProof &&
          userKyc.addressProof.status != "" &&
          userKyc.addressProof.status != "approved") ||
        (userKyc.idProof &&
          userKyc.idProof.status != "" &&
          userKyc.idProof.status != "approved")
      ) {
        toastAlert("success", "Please submit your kyc...");
        history.push("/profile");
        return;
      }
    }
    setBuyAddDetails(e);

    if (e && e != undefined) {
      // setCurrentPost(e)
      let buyOrderData = {
        ...buyOrderValue,
        ...{
          postprefcurrency: e.transferMode,
          buytransferMode: e.transferMode,
          buypaymentdetail: e.paymentDetail,
          buyorderPrice: e.price,
          sellerEmail: e.user_info.email,
          buyQuantity: e.quantity,
          buytoFiatCurrency: e.firstCurrency,
          buytoCryptoCurrency: e.secondCurrency,
          crypto_amount: 0,
          fiat_amount: 0,
          buyOrderId: "",
        },
      };
      setBuyOrderValue(buyOrderData);
    }
    window.$("#BuyModel").modal("show");
    // if(e && e!= undefined){
    //   // setCurrentPost(e)
    //   let formData = { ...formValue, ...{ "postprefcurrency":e.transferMode,
    //   "postminlimit":e.minlimit,
    //   "postmaxlimit":e.maxlimit,
    //   "price":e.price,
    //   "postId": e._id,
    //   "currencyselected":e.firstCurrency,
    //   "BuyorSell":e.BuyorSell,
    //  } }
    //   setFormValue(formData)
    // }
  };
  const handlecrypto = async (e, type) => {
    var fiat_amount = e.currentTarget.value;

    if (type == "buy") {
      var price_limit = buyAddDetails.maxlimit;
      var fiat_price = buyAddDetails.quantity;
    } else {
      var price_limit = sellAddDetails.maxlimit;
      var fiat_price = sellAddDetails.quantity;
    }

    var one = price_limit / fiat_price;
    var crypto_amount = parseFloat(toFixed(fiat_amount / one, 8));
    setFiatAmount(fiat_amount);
    setCryptoAmount(crypto_amount);
  };

  const handlebuycrypto = async (e) => {
    e.preventDefault();
    let id = buyAddDetails && buyAddDetails._id;
    let crypto_amount = cryptoAmount;
    let fiat_amount = fiatAmount;

    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
    };
    let { status, result, message, error } = await buyP2PTrade(
      reqData,
      dispatch
    );
    if (message) {
      toastAlert("error", message, "login");
    } else {
      handleConfirmbuycrypto();
      window.$("#BuyModel").modal("hide");
    }
  };

  const handleConfirmbuycrypto = async (e) => {
    let id = buyAddDetails && buyAddDetails._id;
    let crypto_amount = cryptoAmount;
    // let total_fiat = this.state.total_fiat;
    let fiat_amount = fiatAmount;
    let BuyorSell = "Buy";

    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
      BuyorSell: BuyorSell,
    };
    let { result, loading, message, error } = await buyConfirmP2PTrade(
      reqData,
      dispatch
    );
    if (message) {
      toastAlert("success", message, "login");
      history.push("/Chat/" + result);
    } else {
      // handleConfirmbuycrypto();
      // this.props.history.push('/WalletDepositWithdrawCrypto?coinsend='+"BTC")
      // history.push('/spot/' + pair)
      // history.push('/Chat')
    }
  };

  // Sell process
  // Buy and sell ad
  const sellSingleAd = async (e) => {
    if (!isAuth) {
      toastAlert("error", "Please login");
      return;
    }
    if (userKyc && userKyc) {
      console.log("userKyc---", userKyc);

      if (
        (userKyc.addressProof &&
          userKyc.addressProof.status != "" &&
          userKyc.addressProof.status != "approved") ||
        (userKyc.idProof &&
          userKyc.idProof.status != "" &&
          userKyc.idProof.status != "approved")
      ) {
        toastAlert("success", "Please submit your kyc...");
        history.push("/profile");
        return;
      }
    }

    if (e && e != undefined) {
      setSellAddDetails(e);
      // setCurrentPost(e)
    }
    window.$("#SellModel").modal("show");
  };

  const handlesellcrypto = async (e) => {
    e.preventDefault();
    let id = sellAddDetails && sellAddDetails._id;
    let crypto_amount = cryptoAmount;
    let fiat_amount = fiatAmount;
    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
    };
    let { status, result, message, error } = await buyP2PTrade(
      reqData,
      dispatch
    );
    if (message) {
      toastAlert("error", message, "login");
    } else {
      handleConfirmsellcrypto();
      window.$("#SellModel").modal("hide");
    }
  };
  const handleConfirmsellcrypto = async (e) => {
    let id = sellAddDetails && sellAddDetails._id;
    let crypto_amount = cryptoAmount;
    // let total_fiat = this.state.total_fiat;
    let fiat_amount = fiatAmount;
    let BuyorSell = "Sell";

    let reqData = {
      _id: id,
      userid: userId,
      crypto_amount: crypto_amount,
      fiat_amount: fiat_amount,
      BuyorSell: BuyorSell,
    };
    let { status, result, message, error } = await buyConfirmP2PTrade(
      reqData,
      dispatch
    );
    console.log("status----", status);
    if (status == "success") {
      toastAlert("success", message, "login");
      history.push("/Chat/" + result);
    } else {
      toastAlert("error", message, "login");
      // handleConfirmbuycrypto();
      // this.props.history.push('/WalletDepositWithdrawCrypto?coinsend='+"BTC")
      // history.push('/spot/' + pair)

      // history.push('/Chat')
    }
  };

  // redux
  const theme = useSelector((state) => state.theme);
  console.log(estimateValue, "estimateValue");

  console.log(myaddDetails, "myaddDetailsmyaddDetails");
  return (
    // <div className="dashboard_container page_wrap">
    //   <ScrollToTopOnMount />
    <div className="dashboardMain">
      <div className="dashboardRight afterLoginHeader">
        <Header
          className="header"
          color="transparent"
          routes={dashboardRoutes}
          brand={
            theme == "dark" ? (
              <img
                src={require("../../assets/images/logo_white.svg")}
                alt="logo"
                className="img-fluid"
              />
            ) : (
              <img
                src={require("../../assets/images/logo.svg")}
                alt="logo"
                className="img-fluid"
              />
            )
          }
          rightLinks={<HeaderLinksAfterlogin />}
          fixed
          changeColorOnScroll={{
            height: 20,
            color: "dark",
          }}
          {...rest}
        />
        <div className="dashboardContent userPages peer2peer_section">
          <div className="container">
            <GridContainer>
              <GridItem xs={12} sm={12} md={5} lg={5}>
                <h3 className="dash_title">Peer-to-Peer</h3>
              </GridItem>
              <GridItem xs={12} sm={12} md={7} lg={7}>
                <LastLogin />
                {/* <ul className="profile_dash">
                    <li>New trade pair XRP/BNB will add for trade on next 48 hrs - <span>Admin Announcement</span></li>
                    <li>13-05-2021  15:15, Chrome, 182.254.127  - <span>Last login</span></li>
                  </ul> */}
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <div className="dashboard_box">
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <div className="balance_details_panel pb-0">
                        <div className="balance_details_left">
                          <h5 className="dash_subtitle paddin_title_p">
                            Estimated P2P Balance
                          </h5>
                          <div className="flexDashBalance">
                            <div className="mr-5">
                              <h3>Total Account Balance</h3>
                              <h2 className="balance_color_dash">
                                {userBalance.toFixed(8)} <span>USDT</span>
                              </h2>
                            </div>
                            <div>
                              <h3>Estimated Value:</h3>
                              <h4>INR {convertbalance}</h4>
                            </div>
                          </div>
                        </div>
                        <div className="balance_details_right aligncontans_cen">
                          <div className="chartDash">
                            <img
                              src={require("../../assets/images/p2pimg.png")}
                              alt=""
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                      <div class="peer_clas_parant">
                        <div className="peer2peer_contant_first">
                          <h1>JNC EXCHANGE P2P BUY/SELL YOUR CRYPTO LOCALLY</h1>
                          <p>
                            Peer-to-peer exchange (or P2P exchange) is a
                            marketplace where people can trade crypto directly
                            with each other on their own terms, in virtually any
                            country.
                          </p>
                          <div className="p2p_button_flex">
                            <a href="/postmyad">
                              {" "}
                              <button>Post new Order</button>
                            </a>
                            <a href="/myads">
                              {" "}
                              <button>My Posts</button>
                            </a>

                            <a href="/p2ptradehistory">
                              {" "}
                              <button>Trade History</button>
                            </a>
                          </div>
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h5 className="dash_subtitle paddin_title_p">
                        Vacation Management
                      </h5>

                      <div className="recentad_section flex_recent_section">
                        <div className="recent_title">
                          <h3>Selling Vacation</h3>
                        </div>
                        <div className="Post_id">
                          <p>
                            <span>
                              <Checkbox
                                name="sell_vacation"
                                checked={sell_vac}
                                onChange={handleCheckBox_sell}
                              />{" "}
                              Disable all your advertisment for sell temprarily
                            </span>{" "}
                          </p>
                        </div>

                        <div className="recent_title">
                          <h3>Buying Vacation</h3>
                        </div>
                        <div className="Post_id">
                          <p>
                            <span>
                              <Checkbox
                                name="buy_vacation"
                                checked={buy_vac}
                                onChange={handleCheckBox_buy}
                              />{" "}
                              Disable all your advertisment for buy temprarily
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>
                </div>
              </GridItem>
            </GridContainer>
            <div className="dashboard_box dashHistoryTable onlytab_bordernone p-3">
              <nav>
                <div
                  class="nav nav-tabs primaryNav"
                  id="nav-tab"
                  role="tablist"
                >
                  {unique.length > 0 &&
                    unique.map((item, i) => {
                      // if(item.type== "token"){
                      var tempclass =
                        cryptocurrency == item
                          ? "nav-item nav-link active"
                          : "nav-item nav-link";

                      return (
                        <a
                          onClick={() => {
                            handlecurrenttab(item);
                          }}
                          class={tempclass}
                          id={item}
                          data-toggle="tab"
                          href={`#nav-dash${item}`}
                          role="tab"
                          aria-controls={`nav-dash${item}`}
                          aria-selected="false"
                        >
                          {item}
                        </a>
                      );
                      //  }
                    })}
                  {/* <a class="nav-item nav-link active" id="nav-recentTransaction-tab" data-toggle="tab" href="#nav-recentTransaction" role="tab" aria-controls="nav-recentTransaction" aria-selected="true">BTC </a>
                    <a class="nav-item nav-link" id="nav-loginHistory-tab" data-toggle="tab" href="#nav-loginHistory" role="tab" aria-controls="nav-loginHistory" aria-selected="false">ETH</a>
                    <a class="nav-item nav-link" id="nav-notificationHistory-tab" data-toggle="tab" href="#nav-notificationHistory" role="tab" aria-controls="nav-notificationHistory" aria-selected="false">USDT</a> */}
                </div>
              </nav>
              <div className="boxSpace pt-1">
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-recentTransaction"
                    role="tabpanel"
                    aria-labelledby="nav-recentTransaction-tab"
                  >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <div className="recentad_section">
                          <form className="contact_form mb-0 w100_form_p2p">
                            <div className="settingsSelect clas_Newselect clas_Newselect124">
                              <GridContainer>
                                <GridItem xs={12} sm={7} md={6} lg={4}>
                                  <div className="form-group d-flex_p2p flex-wrwp-mobile_1 paggin_sdf">
                                    <label className="label_color_78">
                                      Enter Amount
                                    </label>
                                    <div className="flot_fixe_ins">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="secondamount"
                                        value={secondamount}
                                        onChange={handleChange}
                                      />
                                      <Select
                                        name="posttocurrency"
                                        value={posttocurrency}
                                        onChange={handleposttocurrency}
                                      >
                                        {currencyData &&
                                          currencyData.length > 0 &&
                                          currencyData.map((item, key) => {
                                            if (item.type == "fiat") {
                                              return (
                                                <MenuItem
                                                  value={item.currencySymbol}
                                                >
                                                  {item.currencySymbol}
                                                </MenuItem>
                                              );
                                            }
                                          })}
                                      </Select>
                                    </div>
                                  </div>
                                  {validateError.secondamount && (
                                    <p className="error-message">
                                      {validateError.secondamount}
                                    </p>
                                  )}
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6} lg={5}>
                                  <div className="form-group d-flex_p2p flex-wrwp-mobile_1">
                                    <label className="label_color_78">
                                      Payments
                                    </label>
                                    <Select
                                      name="postprefcurrency"
                                      value={postprefcurrency}
                                      onChange={handlepostprefcurrency}
                                    >
                                      <MenuItem value="all">All</MenuItem>
                                      <MenuItem value="Bank">Bank</MenuItem>
                                      <MenuItem value="UPI">UPI</MenuItem>
                                      <MenuItem value="Gpay">Gpay</MenuItem>
                                    </Select>
                                    <div className="peer2peer_contant_first ">
                                      <button
                                        className="buttont_poidf buttont_poidf_butn"
                                        onClick={searchfindAds}
                                      >
                                        Search
                                      </button>
                                    </div>
                                  </div>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12} lg={3}>
                                  <p className="refresh_color_78">
                                    <a href="" className="refresh_color_78">
                                      Refresh
                                    </a>
                                  </p>
                                </GridItem>
                              </GridContainer>
                            </div>
                          </form>
                        </div>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem lg={12}>
                        <nav>
                          <div
                            class="nav nav-tabs primaryNav"
                            id="nav-tab"
                            role="tablist"
                          >
                            <a
                              class="nav-item nav-link active"
                              id="nav-buy-tab"
                              data-toggle="tab"
                              href="#nav-buy"
                              role="tab"
                              aria-controls="nav-buy"
                              aria-selected="true"
                            >
                              Buy{" "}
                            </a>
                            <a
                              class="nav-item nav-link"
                              id="nav-sell-tab"
                              data-toggle="tab"
                              href="#nav-sell"
                              role="tab"
                              aria-controls="nav-sell"
                              aria-selected="false"
                            >
                              Sell
                            </a>
                          </div>
                        </nav>
                        <div class="tab-content" id="nav-tabContent">
                          <div
                            class="tab-pane fade show active p-3"
                            id="nav-buy"
                            role="tabpanel"
                            aria-labelledby="nav-buy-tab"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>Advertisers</th>
                                    <th>Price</th>
                                    <th>Limit/Available</th>
                                    <th>Payment</th>
                                    <th>Trade</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {buyads.length > 0 &&
                                    buyads.map((item, i) => {
                                      var perprice = (
                                        item.maxlimit / item.quantity
                                      ).toFixed(2);
                                      //  var available=(item.maxlimit/perprice).toFixed(4);
                                      return (
                                        <tr>
                                          <td>
                                            <div className="buy_table_contant">
                                              <h4 className="">
                                                {item.user_info.email.substring(
                                                  0,
                                                  item.user_info.email.lastIndexOf(
                                                    "@"
                                                  )
                                                )}
                                              </h4>
                                              <span
                                                className="notify_count"
                                                style={{
                                                  position: "relative",
                                                  right: "0px",
                                                }}
                                              >
                                                {item.user_info
                                                  .p2pCompletedSellOrder +
                                                  item.user_info
                                                    .p2pCompletedBuyOrder}{" "}
                                              </span>{" "}
                                              Completed
                                              {/* <p>
                                                {item.user_info
                                                  .p2pCompletedSellOrder +
                                                  item.user_info
                                                    .p2pCompletedBuyOrder}{" "}
                                                Orders Completed<br></br>
                                              </p> */}
                                            </div>
                                          </td>
                                          <td className="balance_amt_detail">
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Total Price :{" "}
                                              </span>
                                              {item.price} {item.secondCurrency}
                                            </p>
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Per Price:
                                              </span>
                                              {(
                                                item.maxlimit / item.quantity
                                              ).toFixed(2)}{" "}
                                              {item.secondCurrency}
                                            </p>
                                          </td>
                                          <td>
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Available Quantity:
                                              </span>
                                              {item.quantity.toFixed(4)}{" "}
                                              {item.firstCurrency}
                                              <br></br>
                                              {/* <span className="mar-lrft0_rigt ">Available:</span>{(item.maxlimit/item.marketprice).toFixed(2)} {item.firstCurrency}<br></br>  */}
                                              <span className="mar-lrft0_rigt ">
                                                Available Limit:
                                              </span>
                                              {item.minlimit} - {item.maxlimit}
                                              {item.secondCurrency}
                                            </p>
                                          </td>
                                          <td>
                                            <span className="mar-lrft0_rigt ">
                                              Preferred Transfer:
                                            </span>
                                            {item.transferMode}
                                          </td>
                                          <td className="buy_button_op">
                                            <div>
                                              <button
                                                onClick={() =>
                                                  buySingleAd(item)
                                                }
                                              >
                                                Buy
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  {buyads.length == 0 && (
                                    <tr style={{ textAlign: "center" }}>
                                      <td colspan="5">Oops No Result Found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div
                            class="tab-pane fade p-3"
                            id="nav-sell"
                            role="tabpanel"
                            aria-labelledby="nav-sell-tab"
                          >
                            <div className="table-responsive">
                              <table className="table mb-0">
                                <thead>
                                  <tr>
                                    <th>Advertisers</th>
                                    <th>Price</th>
                                    <th>Limit/Available</th>
                                    <th>Payment</th>
                                    <th>Trade</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {sellads.length > 0 &&
                                    sellads.map((item, i) => {
                                      return (
                                        <tr>
                                          <td>
                                            <div className="buy_table_contant">
                                              <h4>
                                                {item.user_info.email.substring(
                                                  0,
                                                  10
                                                )}
                                                ..
                                              </h4>
                                              {/* <p>{item.user_info.p2pCompletedSellOrder+item.user_info.p2pCompletedBuyOrder} orders,<br></br>
                                                        97.5% completion</p></div></td> */}
                                              <span
                                                className="notify_count"
                                                style={{
                                                  position: "relative",
                                                  right: "0px",
                                                }}
                                              >
                                                {item.user_info
                                                  .p2pCompletedSellOrder +
                                                  item.user_info
                                                    .p2pCompletedBuyOrder}{" "}
                                              </span>{" "}
                                              Completed
                                            </div>
                                          </td>
                                          <td className="balance_amt_detail">
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Total Price :{" "}
                                              </span>
                                              {item.price} {item.secondCurrency}
                                            </p>
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Per Price:
                                              </span>
                                              {(
                                                item.maxlimit / item.quantity
                                              ).toFixed(4)}{" "}
                                              {item.secondCurrency}
                                            </p>
                                          </td>
                                          <td>
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Available Quantity:
                                              </span>
                                              {item.quantity.toFixed(4)}{" "}
                                              {item.firstCurrency}
                                              <br></br>
                                              {/* <span className="mar-lrft0_rigt ">Available:</span>{(item.maxlimit/item.marketprice).toFixed(2)} {item.firstCurrency}<br></br>  */}
                                              <span className="mar-lrft0_rigt ">
                                                Available Limit :
                                              </span>
                                              {item.minlimit} - {item.maxlimit}{" "}
                                              {item.secondCurrency}
                                            </p>
                                          </td>
                                          <td>
                                            <p>
                                              <span className="mar-lrft0_rigt ">
                                                Preferred Transfer:
                                              </span>
                                              {item.transferMode}
                                            </p>
                                          </td>
                                          <td className="buy_button_op">
                                            <div>
                                              <button
                                                className="sellcolor_o"
                                                onClick={() =>
                                                  sellSingleAd(item)
                                                }
                                              >
                                                Sell
                                              </button>
                                            </div>
                                          </td>
                                          {/* <button data-toggle="modal" data-target="#BuyModel">Buy</button></div></td> */}
                                        </tr>
                                      );
                                    })}
                                  {sellads.length == 0 && (
                                    <tr style={{ textAlign: "center" }}>
                                      <td colspan="5">Oops No Result Found</td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-loginHistory"
                    role="tabpanel"
                    aria-labelledby="nav-loginHistory-tab"
                  ></div>
                </div>
              </div>
            </div>
            {/* <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <div className="peer2peer_contant_first ">
                    <button data-toggle="modal" data-target="#confirm_payment">Show more</button>
                  </div>
                </GridItem>
              </GridContainer> */}
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="confirm_payment"
        tabindex="-1"
        role="dialog"
        aria-labelledby="confirm_payment"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body">
                <h2>Confirm payment</h2>
                <img
                  src={require("../../assets/images/pulp.png")}
                  alt=""
                  className="img-fluid"
                />
                <p>Please confirm that payment has been made to the seller. </p>
                <p>Malicious clicks will lead to account frozen</p>
                <div className="new_confirm_pay_button">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button className="button1 ">Confirm</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction modalzindex"
        id="BuyModel"
        tabindex="-1"
        role="dialog"
        aria-labelledby="BuyModel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">
                Buy
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  className="grid_wh_padding"
                >
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="f-20 colo_er_opo">
                        {buyAddDetails &&
                          buyAddDetails.user_info &&
                          buyAddDetails.user_info.email}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {buyAddDetails &&
                          buyAddDetails.user_info &&
                          buyAddDetails.user_info.p2pCompletedBuyOrder +
                            buyAddDetails.user_info.p2pCompletedSellOrder}{" "}
                        orders completed
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Per Price</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {(buyAddDetails && buyAddDetails.maxlimit) /
                          (buyAddDetails && buyAddDetails.quantity)}{" "}
                        {buyAddDetails && buyAddDetails.secondCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Payment Time Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">30 Minutes</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Available</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {buyAddDetails && buyAddDetails.quantity} -{" "}
                        {buyAddDetails && buyAddDetails.firstCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Trade Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        Min:{buyAddDetails && buyAddDetails.minlimit} - Max:
                        {buyAddDetails && buyAddDetails.maxlimit}{" "}
                        {buyAddDetails && buyAddDetails.secondCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Seller’s Payment</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {buyAddDetails && buyAddDetails.transferMode}{" "}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      {buyAddDetails && buyAddDetails.paymentDetail && (
                        <span className="colo_er_opo">Payment Detail</span>
                      )}
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {buyAddDetails && buyAddDetails.transferMode == "Gpay"
                          ? buyAddDetails.paymentDetail && (
                              <img
                                src={buyAddDetails.paymentDetail}
                                height="100"
                                width="100"
                              />
                            )
                          : buyAddDetails.paymentDetail}{" "}
                      </span>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={5}
                  className="bor_lef_ds solo_padding "
                >
                  {/* <form className="contact_form mb-0"> */}
                  <div className="contact_form mb-0">
                    <div className="form-group">
                      <label className="color_whit_7 w100_form_p2p">
                        I want to pay{" "}
                      </label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="fiatAmount"
                          name="fiatAmount"
                          onChange={(e) => handlecrypto(e, "buy")}
                          onInput={validPositive}
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {buyAddDetails && buyAddDetails.secondCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="color_whit_7 ">I will receive</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          name="cryptoAmount"
                          id="cryptoAmount"
                          value={cryptoAmount}
                          readOnly="true"
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {buyAddDetails && buyAddDetails.firstCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className="btn btn-primary text-uppercase py-2 w-100"
                        onClick={handlebuycrypto}
                      >
                        Trade Request
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </GridItem>
              </GridContainer>
            </div>
            {/* <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary">Save changes</button>
              </div> */}
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div
        class="modal fade buy_sellaction confirm_sellaction modalzindex"
        id="edit_post"
        tabindex="-1"
        role="dialog"
        aria-labelledby="edit_post"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <div className="confirm_body ">
                <h2>Confirm My Post</h2>
                <img
                  src={require("../../assets/images/postconfirm.png")}
                  alt=""
                  className="img-fluid"
                />
                <div className="model_detail_text">
                  <div className="detail_text_model">
                    <span className="righttext">Type:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{BuyorSell}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Cryptocurrency:</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">{currencyselected}</span>
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price:</span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="number"
                      class="form-control"
                      name="price"
                      value={price}
                      onChange={handleChange}
                    />
                    {validateError.price && (
                      <p className="error-message">{validateError.price}</p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price Limit From: </span>
                  </div>
                  <div className="detail_text_model">
                    <input
                      type="number"
                      class="form-control"
                      name="postminlimit"
                      value={postminlimit}
                      onChange={handleChange}
                    />
                    {validateError.postminlimit && (
                      <p className="error-message">
                        {validateError.postminlimit}
                      </p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Price Limit To: </span>
                  </div>
                  <div className="detail_text_model">
                    <span className="colo_fff">
                      <input
                        type="number"
                        class="form-control"
                        name="postmaxlimit"
                        value={postmaxlimit}
                        onChange={handleChange}
                      />
                    </span>
                    {validateError.postmaxlimit && (
                      <p className="error-message">
                        {validateError.postmaxlimit}
                      </p>
                    )}
                  </div>
                  <div className="detail_text_model">
                    <span className="righttext">Preferred Payments: </span>
                  </div>
                  <div className="detail_text_model">
                    <Select
                      name="postprefcurrency"
                      value={postprefcurrency}
                      onChange={handlepostprefcurrency}
                    >
                      <MenuItem value="Bank">Bank</MenuItem>
                      <MenuItem value="UPI">UPI</MenuItem>
                      <MenuItem value="Gpay">Gpay</MenuItem>
                    </Select>
                    {validateError.postprefcurrency && (
                      <p className="error-message">
                        {validateError.postprefcurrency}
                      </p>
                    )}
                  </div>
                </div>
                <div className="new_confirm_pay_button mat_205">
                  <button className="button1 button2" data-dismiss="modal">
                    Cancel
                  </button>
                  <button onClick={updatePost} className="button1">
                    Submit Post
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Sell ad Modal --> */}
      <div
        class="modal fade buy_sellaction modalzindex"
        id="SellModel"
        tabindex="-1"
        role="dialog"
        aria-labelledby="SellModel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle blew">
                Sell
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={7}
                  className="grid_wh_padding"
                >
                  <div className="model_detail_text">
                    <div className="detail_text_model">
                      <span className="f-20 colo_er_opo">
                        {sellAddDetails &&
                          sellAddDetails.user_info &&
                          sellAddDetails.user_info.email}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {sellAddDetails &&
                          sellAddDetails.user_info &&
                          sellAddDetails.user_info.p2pCompletedBuyOrder +
                            sellAddDetails.user_info.p2pCompletedSellOrder}{" "}
                        orders completed
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Per Price</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {(sellAddDetails && sellAddDetails.maxlimit) /
                          (sellAddDetails && sellAddDetails.quantity)}{" "}
                        {sellAddDetails && sellAddDetails.secondCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Payment Time Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">30 Minutes</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Available </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_green_o">
                        {sellAddDetails && sellAddDetails.quantity}{" "}
                        {sellAddDetails && sellAddDetails.firstCurrency}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Seller’s Payment</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {sellAddDetails && sellAddDetails.transferMode}{" "}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      {sellAddDetails && sellAddDetails.paymentDetail && (
                        <span className="colo_er_opo">Payment Detail</span>
                      )}
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        {sellAddDetails && sellAddDetails.transferMode == "Gpay"
                          ? sellAddDetails.paymentDetail && (
                              <img
                                src={sellAddDetails.paymentDetail}
                                height="100"
                                width="100"
                              />
                            )
                          : sellAddDetails.paymentDetail}{" "}
                      </span>
                    </div>
                    <div className="detail_text_model">
                      <span className="colo_er_opo">Trade Limit</span>
                    </div>
                    <div className="detail_text_model">
                      <span className="color_whit_7">
                        Min:{sellAddDetails && sellAddDetails.minlimit} - Max:
                        {sellAddDetails && sellAddDetails.maxlimit}{" "}
                      </span>
                    </div>
                  </div>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={5}
                  className="bor_lef_ds solo_padding "
                >
                  {/* <form className="contact_form mb-0"> */}
                  <div className="contact_form mb-0">
                    <div className="form-group">
                      <label className="color_whit_7 w100_form_p2p">
                        I will receive{" "}
                      </label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          id="fiatAmount"
                          name="fiatAmount"
                          onChange={(e) => handlecrypto(e, "sell")}
                          onInput={validPositive}
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {sellAddDetails && sellAddDetails.secondCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="color_whit_7 ">I will sell</label>
                      <div class="input-group ">
                        <input
                          type="text"
                          class="form-control"
                          name="cryptoAmount"
                          id="cryptoAmount"
                          value={cryptoAmount}
                          readOnly="true"
                        />
                        <div class="input-group-append">
                          <button className="inpor_inside_buttons">
                            {sellAddDetails && sellAddDetails.firstCurrency}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-0">
                      <button
                        className="btn btn-primary text-uppercase py-2 w-100"
                        onClick={handlesellcrypto}
                      >
                        Trade Request
                      </button>
                    </div>
                  </div>
                  {/* </form> */}
                </GridItem>
              </GridContainer>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    // </div>
  );
}
