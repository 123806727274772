// import package
import React, { createRef, useState, useEffect } from 'react';
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

// import action
import { resetPassword } from '../../actions/users';

// import lib
import validation from './validation';
import isEmpty from '../../lib/isEmpty';
import { toastAlert } from '../../lib/toastAlert';

const initialFormValue = {
    'password': '',
    'confirmPassword': '',
}

const ResetPassword = () => {
    const history = useHistory();
    const { authToken } = useParams();

    // states
    const [formValue, setFormValue] = useState(initialFormValue);
    const [toched, setToched] = useState({});
    const [validateError, setValidateError] = useState({});
    const [loader, setLoader] = useState();

    const { password, confirmPassword } = formValue;

    // function
    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
        setValidateError(validation(formData))
    }

    const handleBlur = (e) => {
        const { name } = e.target;
        setToched({ ...toched, ...{ [name]: true } })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        let reqData = {
            password,
            confirmPassword,
            authToken
        }
        let { status, loading, error, message } = await resetPassword(reqData);
        setLoader(loading);
        if (status == "success") {
            setFormValue(initialFormValue)
            setToched({})
            setValidateError(validation(initialFormValue))
            history.push("/login");
            toastAlert('success', message, 'resetPassword');
        } else {
            if (error) {
                setValidateError(error);
            }
            toastAlert('error', message, 'resetPassword');

        }
    }

    useEffect(() => {
        setValidateError(validation(formValue))
    }, [])

    return (
        <div className="login_container">   
            <div className="container">
                <div className="row align-items-center">
                    {/* <div className="col-sm-12 col-md-6">
                        <div className="logo_bg_shadow" data-aos="fade-up">
                        <img src={require("../../assets/images/logo_bg.svg")} alt="logo" className="img-fluid" />
                        </div>
                    </div> */}
                    <div className="col-sm-12 col-md-6 mx-auto">
                        <form className="login_form p-4 mb-4" data-aos="fade-up">
                            <h2 className="text-left">Reset Password</h2>
                            {/* <h6>Input your registered email address, we’ll send you reset password.</h6> */}
                            <div className="form-group pt-3">
                                <input
                                    className="form-control"
                                    placeholder="Password"
                                    name="password"
                                    type="password"
                                    value={password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {toched.password && validateError.password && <p className="error-message">{validateError.password}</p>}

                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {toched.confirmPassword && validateError.confirmPassword && <p className="error-message">{validateError.confirmPassword}</p>}
                            </div>

                            <div className="form-group">
                                <Button
                                    onClick={handleFormSubmit}
                                    disabled={!isEmpty(validateError)}
                                >
                                    {loader && <i class="fas fa-spinner fa-spin"></i>}Submit
                                </Button>
                            </div>
                            <div className="d-flex">
                                <Link to="/login" className="ml-auto">Login</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;