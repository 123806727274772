// import package
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { currencySymbol } from "../../lib/pairHelper";
import { toFixed } from "../../lib/roundOf";
// import component
// import FiatWithdraw from './FiatWithdraw';
// import FiatDeposit from './FiatDeposit';
// import CoinDeposit from './CoinDeposit';
// import CoinWithdraw from './CoinWithdraw';

// import action
import { getAssetData } from "../../actions/walletAction";

const P2pUserWalletList = () => {
  const { t, i18n } = useTranslation();

  // redux-state
  const walletData = useSelector((state) => state.wallet);
  console.log(walletData);
  // state
  const [depositKey, setDepositKey] = useState(-1);
  const [withdrawKey, setWithdrawKey] = useState(-1);
  const [assetData, setAsstData] = useState({});

  const userSetting = useSelector((state) => state.userSetting);
  const priceConversion = useSelector((state) => state.priceConversion);
  return (
    <>
      {walletData &&
        walletData.length > 0 &&
        walletData.map((item, key) => {
          console.log("itemitemitemitemitem", item);
          //   if (item.currency.type != "fiat") {
          let priceCNV;

          if (userSetting && priceConversion && priceConversion.length > 0) {
            priceCNV = priceConversion.find(
              (el) =>
                el.baseSymbol == item.currency.currencySymbol &&
                el.convertSymbol == userSetting.currencySymbol
            );
          }
          return (
            <>
              <div className="dashboard_box mb-2">
                <div className="walletCard">
                  <div className="walletCardLeft">
                    <div className="currencyName">
                      <img
                        src={item.currency.currencyImage}
                        alt=""
                        className="img-fluid"
                      />
                      {item.currency.currencyName}
                    </div>
                    {item.currency.type != "fiat" ? (
                      <div className="currencyPrice">
                        {toFixed(item.p2pbalance, 8)}{" "}
                        <small>
                          {item.currency.type == "crypto"
                            ? item.currency.CoinpaymentCode
                            : `${
                                item.currency.currencySymbol
                              }.${item.currency.tokenType.toUpperCase()}`}
                        </small>
                      </div>
                    ) : (
                      <div className="currencyPrice">
                        {toFixed(item.p2pbalance, 8)}{" "}
                        <small>{item.currency.currencySymbol}</small>
                      </div>
                    )}
                    <div className="currencyPrice">
                      {priceCNV && (
                        <>
                          <small>
                            {currencySymbol(userSetting.currencySymbol)}
                          </small>{" "}
                          {toFixed(item.p2pbalance * priceCNV.convertPrice, 2)}
                        </>
                      )}
                    </div>
                  </div>
                  {/* <div className="walletCardRight">
                                        <div className="textLinkGroup">
                                            [
                                            <Link href="#"
                                                onClick={() => {
                                                    setDepositKey(key == depositKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Deposit
                                            </Link>
                                        ]
                                        [
                                            <Link href="#"
                                                onClick={() => {
                                                    setWithdrawKey(key == withdrawKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Withdraw
                                            </Link>
                                        ]

                                        {
                                                ['crypto', 'token'].includes(item.currency.type) && <>
                                                    {' '}[ <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Trade</a> ]
                                            </>
                                            }
                                        </div>
                                    </div> */}
                </div>
              </div>

              {/* <Collapse in={key == depositKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinDeposit
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatDeposit
                                            assetData={item}
                                        />
                                    }

                                </div>
                            </Collapse> */}

              {/* <Collapse in={key == withdrawKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinWithdraw
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatWithdraw
                                            assetData={item}
                                        />
                                    }
                                </div>


                            </Collapse> */}
            </>
          );
          //   }
        })}
      {/* {
                walletData && walletData.length > 0 && walletData.map((item, key) => {
                    return (
                        <>
                            <div className="dashboard_box mb-2">
                                <div className="walletCard">
                                    <div className="walletCardLeft">
                                        <div className="currencyName">
                                            <img src={item.currency.currencyimage} alt="" className="img-fluid" />
                                            {item.currency.currencyName}
                                        </div>
                                        <div className="currencyPrice">
                                            {item.spotwallet}{" "}
                                            <small>{item.currency.currencySymbol}</small>
                                        </div>
                                    </div>
                                    <div className="walletCardRight">
                                        <div className="textLinkGroup">
                                            [
                                            <Link href="#"
                                                onClick={() => {
                                                    setDepositKey(key == depositKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Deposit
                                            </Link>
                                        ]
                                        [
                                            <Link href="#"
                                                onClick={() => {
                                                    setWithdrawKey(key == withdrawKey ? -1 : key)
                                                    setAsstData(item)
                                                }}
                                            >
                                                Withdraw
                                            </Link>
                                        ]

                                        {
                                                ['crypto', 'token'].includes(item.currency.type) && <>
                                                    {' '}[ <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Trade</a> ]
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Collapse in={key == depositKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinDeposit
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatDeposit
                                            assetData={item}
                                        />
                                    }

                                </div>
                            </Collapse>

                            <Collapse in={key == withdrawKey}>
                                <div className="contact_form settingsSelect mb-0">

                                    {
                                        assetData && assetData.currency && ['crypto', 'token'].includes(assetData.currency.type) && <CoinWithdraw
                                            assetData={item}
                                        />
                                    }

                                    {
                                        assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && <FiatWithdraw
                                            assetData={item}
                                        />
                                    }
                                </div>


                            </Collapse>
                        </>
                    )
                })
            } */}
    </>
  );
};

export default P2pUserWalletList;
