// import package
import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getCMSPage } from "../../actions/commonAction";

const HomeBanner = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [content, setContent] = useState("");

  useEffect(() => {
    fetchCmsPage();
  }, []);

  const fetchCmsPage = async () => {
    try {
      const { status, loading, result } = await getCMSPage("home_head_banner");
      if (status == "success") {
        setContent(result);
      }
    } catch (err) {}
  };

  return (
    <div className="banner_container pt-5 pb-lg-0 p-md-5">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-11 m-auto" data-aos="fade-up">
            <div className="new_home_section_o text-center">
              {/* <h1>Trade with the Best Cryptocurrency exchange <br />in India.</h1>
                                <p>Over 740 cryptocurrency and fiat currency pairings are available for trading, including Bitcoin, Ethereum, and BNB pairs in Mudra Exchange.</p> */}

              <div
                dangerouslySetInnerHTML={{ __html: content && content.content }}
              />

              <Button
                className="primary_btn mt-md-5 pt-3 mb-md-0 mt-3 mb-4"
                onClick={() => {
                  history.push("/register");
                }}
              >
                {t("GET_STARTED")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
