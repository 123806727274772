// import package
import React, { useState, useEffect } from "react";
import { Slider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import action
import { orderPlace, getfees } from "../../actions/spotTradeAction";

// import lib
import isEmpty from "../../lib/isEmpty";
import { encryptObject } from "../../lib/cryptoJS";
import { toastAlert } from "../../lib/toastAlert";
import { toFixed } from "../../lib/roundOf";
import validation from "./validation";
import TokenFee from "./TokenFee";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 25,
    label: "25%",
  },
  {
    value: 50,
    label: "50%",
  },
  {
    value: 75,
    label: "75%",
  },
  {
    value: 100,
    label: "100%",
  },
];
let initialPrice = "";

function valuetext(value) {
  return `${value}%`;
}

const initialFormValue = {
  price: "",
  quantity: "",
  total: "",
  pricesell: "",
  quantitysell: "",
  totalsell: "",
};

const LimitOrder = (props) => {
  const history = useHistory();
  // props
  const { buyorsell } = props;

  // state
  const [formValue, setFormValue] = useState(initialFormValue);
  const [loader, setLoader] = useState();
  const [fees, setfees] = useState(0);
  const {
    price,
    quantity,
    total,
    pricesell,
    quantitysell,
    totalsell,
  } = formValue;

  // redux-state
  const tradePair = useSelector((state) => state.tradePair);
  const marketPriceData = useSelector((state) => state.marketPrice);
  const { isAuth } = useSelector((state) => state.auth);
  const orderBookDetail = useSelector((state) => state.orderBookDetail);
  const { firstCurrency, secondCurrency } = useSelector(
    (state) => state.tradeAsset
  );
  const { tokenfee } = useSelector((state) => state.TokenFee);

  function IsValid(value, degit) {
    var split = value.split(".");

    if (split && split[1] && split[1] != "")
      if (split[1].length > degit) {
        return true;
      }

    return false;
  }

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (name == "price") {
      if (IsValid(value, tradePair.secondFloatDigit)) {
        return;
      }
    }
    if (name == "quantity") {
      if (IsValid(value, tradePair.firstFloatDigit)) {
        return;
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };

    if (!isEmpty(formData.price) && !isEmpty(formData.quantity)) {
      let totalPrice = formData.price * formData.quantity;
      formData = {
        ...formData,
        ...{ ["total"]: toFixed(totalPrice, tradePair.secondFloatDigit) },
      };
    }
    setFormValue(formData);
  };
  const handleChangesell = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (name == "pricesell") {
      if (IsValid(value, tradePair.secondFloatDigit)) {
        return;
      }
    }
    if (name == "quantitysell") {
      if (IsValid(value, tradePair.firstFloatDigit)) {
        return;
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };

    if (!isEmpty(formData.pricesell) && !isEmpty(formData.quantitysell)) {
      let totalPricesell = formData.pricesell * formData.quantitysell;
      formData = {
        ...formData,
        ...{
          ["totalsell"]: toFixed(totalPricesell, tradePair.secondFloatDigit),
        },
      };
    }
    setFormValue(formData);
  };

  const handleTotal = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (name == "total") {
      if (IsValid(value, tradePair.secondFloatDigit)) {
        return;
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };
    if (!isEmpty(formData.price) && !isEmpty(formData.total)) {
      let totalPrice = formData.total / formData.price;
      formData = {
        ...formData,
        ...{ ["quantity"]: toFixed(totalPrice, tradePair.firstFloatDigit) },
      };
    }
    setFormValue(formData);
  };

  const handleTotalsell = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (!/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (name == "totalsell") {
      if (IsValid(value, tradePair.secondFloatDigit)) {
        return;
      }
    }

    let formData = { ...formValue, ...{ [name]: value } };
    if (!isEmpty(formData.pricesell) && !isEmpty(formData.totalsell)) {
      let totalPricesell = formData.totalsell / formData.pricesell;
      formData = {
        ...formData,
        ...{
          ["quantitysell"]: toFixed(totalPricesell, tradePair.firstFloatDigit),
        },
      };
    }
    setFormValue(formData);
  };

  const handlePercentage = (e, percentage) => {
    let priceValue = 0,
      formData = {};
    if (!isEmpty(price) && !isNaN(price)) {
      priceValue = price;
    } else if (
      !isEmpty(marketPriceData.markPrice) &&
      !isNaN(marketPriceData.markPrice)
    ) {
      priceValue = marketPriceData.markPrice;
      formData["price"] = marketPriceData.markPrice;
    }

    if (buyorsell == "buy") {
      let userBalance =
        secondCurrency && secondCurrency.spotwallet > 0
          ? secondCurrency.spotwallet
          : 0;
      formData["total"] = toFixed(
        userBalance * (percentage / 100),
        tradePair.secondFloatDigit
      );
      formData["quantity"] = toFixed(
        formData["total"] / priceValue,
        tradePair.firstFloatDigit
      );
    } else if (buyorsell == "sell") {
      let userBalance =
        firstCurrency && firstCurrency.spotwallet > 0
          ? firstCurrency.spotwallet
          : 0;
      formData["quantity"] = toFixed(
        userBalance * (percentage / 100),
        tradePair.firstFloatDigit
      );
      formData["total"] = toFixed(
        formData["quantity"] * priceValue,
        tradePair.secondFloatDigit
      );
    }
    setFormValue({ ...formValue, ...formData });
  };

  const handleSubmit = async (buyorsell) => {
    setFormValue(initialFormValue);
    try {
      let reqData;
      if (buyorsell == "buy") {
        reqData = {
          price: price,
          quantity: quantity,
          buyorsell: buyorsell,
          orderType: "limit",
          spotPairId: tradePair.pairId,
          newdate: new Date(),
          tokenfee: tokenfee,
        };
      } else {
        reqData = {
          price: pricesell,
          quantity: quantitysell,
          buyorsell: buyorsell,
          orderType: "limit",
          spotPairId: tradePair.pairId,
          newdate: new Date(),
          tokenfee: tokenfee,
        };
      }

      const validateError = await validation(reqData);
      if (!isEmpty(validateError)) {
        toastAlert(
          "error",
          validateError[Object.keys(validateError)[0]],
          "limitOrder"
        );
        return;
      }
      setLoader(true);

      let encryptToken = {
        token: encryptObject(reqData),
      };

      let { status, loading, message, error, result } = await orderPlace(
        encryptToken
      );
      setLoader(loading);
      if (status == "success") {
        setFormValue(initialFormValue);

        // if (result.userAsset._id == firstCurrency._id) {
        //     setUserFirstCurrency(result.userAsset, dispatch)
        // } else if (result.userAsset._id == secondCurrency._id) {
        //     setUserSecondCurrency(result.userAsset, dispatch)
        // }

        toastAlert("success", message, "spotOrder");
      } else {
        // console.log(error, "errorerrorerrorerror");
        if (!isEmpty(error)) {
          toastAlert("error", error[Object.keys(error)[0]], "limitOrder");
          return;
        }
        toastAlert("error", message, "spotOrder");
      }
    } catch (err) {}
  };

  const getownfess = async () => {
    const { result, status } = await getfees();
    if (result.data) {
      setfees(result.data.percentage);
    }
  };

  useEffect(() => {
    let formData = formValue;
    if (isEmpty(initialPrice) && !isEmpty(marketPriceData)) {
      initialPrice = marketPriceData.markPrice;
      formData = { ...formData, ...{ price: marketPriceData.markPrice } };
      setFormValue(formData);
    }
  }, [marketPriceData]);

  useEffect(() => {
    let formData = initialFormValue;
    if (!isEmpty(tradePair) && !isEmpty(marketPriceData)) {
      formData = {
        ...initialFormValue,
        ...{
          price: marketPriceData.markPrice,
          pricesell: marketPriceData.markPrice,
        },
      };
      setFormValue(formData);
    }
    getownfess();
  }, [tradePair]);

  useEffect(() => {
    if (orderBookDetail && !isEmpty(orderBookDetail.price)) {
      setFormValue({
        ...formValue,
        ...{
          price: orderBookDetail.price,
          pricesell: marketPriceData.markPrice,
        },
      });
    }
  }, [orderBookDetail]);

  return (
    <div>
      <div className="placeOrderBox contact_form d-flex justify-content-between align-item-center">
        <h3 className="mr-4 mt-2">
          <small>Balance</small>{" "}
          <span>
            {toFixed(
              secondCurrency && secondCurrency.spotwallet,
              tradePair.secondFloatDigit
            )}{" "}
            {tradePair && tradePair.secondCurrencySymbol}
          </span>
        </h3>
        <h3 className="mt-2">
          <small>Balance</small>{" "}
          <span>
            {toFixed(
              firstCurrency && firstCurrency.spotwallet,
              tradePair.firstFloatDigit
            )}{" "}
            {tradePair && tradePair.firstCurrencySymbol}
          </span>
        </h3>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div className="placeOrderBox contact_form">
            <div className="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="btnType1">Price</span>
                </div>
                <input
                  type="text"
                  className="form-control text-right borderZero"
                  name="price"
                  value={price}
                  onChange={handleChange}
                />
                <div class="input-group-append">
                  <span class="btnType1">
                    {tradePair && tradePair.secondCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="btnType1">Amount</span>
                </div>
                <input
                  type="text"
                  className="form-control text-right borderZero"
                  name="quantity"
                  value={quantity}
                  onChange={handleChange}
                />
                <div class="input-group-append">
                  <span class="btnType1">
                    {tradePair && tradePair.firstCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="form-group px-3">
        <Slider
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-custom"
          step={1}
          valueLabelDisplay="auto"
          marks={marks}
          defaultValue={0}
          onChange={handlePercentage}
          disabled={!isAuth}
        />
      </div> */}
            <div className="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="btnType1">Total</span>
                </div>
                <input
                  type="text"
                  class="form-control text-right borderZero"
                  name="total"
                  value={total}
                  onChange={handleTotal}
                />
                <div class="input-group-append">
                  <span class="btnType1">
                    {tradePair && tradePair.secondCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>

            <TokenFee />

            {isAuth && (
              <div className="ButtonFullWidth px-0">
                <button
                  className="btn BuyNavButton btn-block w-100"
                  onClick={() => handleSubmit("buy")}
                >
                  Buy {tradePair && tradePair.firstCurrencySymbol}
                </button>
              </div>
            )}

            {!isAuth && (
              <div className="ButtonFullWidth px-0">
                <button
                  className="btn BuyNavButton"
                  onClick={() => history.push("/login")}
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6} lg={6}>
          <div className="placeOrderBox contact_form">
            <div className="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="btnType1">Price</span>
                </div>
                <input
                  type="text"
                  className="form-control text-right borderZero"
                  name="pricesell"
                  value={pricesell}
                  onChange={handleChangesell}
                />
                <div class="input-group-append">
                  <span class="btnType1">
                    {tradePair && tradePair.secondCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="btnType1">Amount</span>
                </div>
                <input
                  type="text"
                  className="form-control text-right borderZero"
                  name="quantitysell"
                  value={quantitysell}
                  onChange={handleChangesell}
                />
                <div class="input-group-append">
                  <span class="btnType1">
                    {tradePair && tradePair.firstCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>
            {/* <div className="form-group px-3">
        <Slider
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-custom"
          step={1}
          valueLabelDisplay="auto"
          marks={marks}
          defaultValue={0}
          onChange={handlePercentage}
          disabled={!isAuth}
        />
      </div> */}
            <div className="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="btnType1">Total</span>
                </div>
                <input
                  type="text"
                  class="form-control text-right borderZero"
                  name="totalsell"
                  value={totalsell}
                  onChange={handleTotalsell}
                />
                <div class="input-group-append">
                  <span class="btnType1">
                    {tradePair && tradePair.secondCurrencySymbol}
                  </span>
                </div>
              </div>
            </div>

            <TokenFee />

            {isAuth && (
              <div className="ButtonFullWidth px-0">
                <button
                  className="btn SellNavButton btn-block w-100"
                  onClick={() => handleSubmit("sell")}
                >
                  Sell {tradePair && tradePair.firstCurrencySymbol}
                </button>
              </div>
            )}

            {!isAuth && (
              <div className="ButtonFullWidth px-0">
                <button
                  className="btn BuyNavButton"
                  onClick={() => history.push("/login")}
                >
                  Login
                </button>
              </div>
            )}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default LimitOrder;
