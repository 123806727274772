// import lib
import isEmpty from '../../lib/isEmpty';

const validation = value => {
    let errors = {};
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG)$/;

   
    if (value.qrImage && value.qrImage.size) {
        if (value.qrImage.size > 1000000) {
            errors.frontImage = "Image Size is too Large"
        } else if (!imageFormat.test(value.qrImage.name)) {
            errors.frontImage = "Invalid Image Format"
        }
    } else {
        errors.frontImage = "Image is Required";
    }
  
    return errors;
}

export default validation;