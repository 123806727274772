// import package
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import component
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import P2pUserWalletList from "./P2pUserWalletList";
// import WalletTransfer from './WalletTransfer';
import LastLogin from "../Dashboard/LastLogin";
import { getP2PPairList } from "../../actions/p2paction";
import UserBalance from "./UserBalance";
import WalletTransfer from "../Wallet/WalletTransfer";
const P2pwallet = () => {
  const dispatch = useDispatch();
  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const [spotpair, setSpotpair] = useState([]);
  const [btcbalance, setBtcBalance] = useState(0);
  const [indexprice, setIndexprice] = useState(0);
  const [estvalue, setEstvalue] = useState();
  const p2pSpotPair = async () => {
    // this.P2pwallet();
    try {
      const { result, status, loading } = await getP2PPairList(dispatch);

      if (result) {
        setSpotpair(result);
        result.length > 0 &&
          result.map((item, key) => {
            if (
              item.first_currency == "USDT" &&
              item.second_currency == "USD"
            ) {
              if (btcbalance != undefined && btcbalance != 0) {
                var est_value = (item.index_price * btcbalance).toFixed(2);
                setEstvalue(est_value);
              }
            }
          });
      }
    } catch (err) {}
  };

  useEffect(() => {
    // wallData();
  }, []);

  useEffect(() => {
    if (walletData && walletData.length > 0) {
      walletData.map((item, key) => {
        console.log("Wallet Item---", item);
        if (item.currencySymbol == "USDT") {
          setBtcBalance(item.p2pbalance);
        }
      });
    }
    p2pSpotPair();
  }, [walletData, btcbalance]);

  console.log("btcbalancebtcbalancebtcbalance", spotpair);
  return (
    <div className="dashboardContent userPages">
      <div className="container">
        <GridContainer>
          <GridItem xs={12} sm={12} md={5} lg={5}>
            <h3 className="dash_title">Wallet</h3>
          </GridItem>
          <GridItem xs={12} sm={12} md={7} lg={7}>
            <LastLogin />
          </GridItem>
        </GridContainer>
        <div className="dashboard_box mb-2">
          <GridContainer>
            {/* <GridItem xs={12} sm={12} md={12} lg={12}>
              <h5 className="dash_subtitle">Estimated Balance</h5>
              <div className="balance_details_panel">
                <div className="balance_details_left d-flex justify-content-start align-items-center">
                  <div className="mr-5">
                    <h3>Total Account Balance</h3>
                    <h2>
                      {btcbalance.toFixed(8)}
                      <span>USDT</span>
                    </h2>
                  </div>
                  <div>
                    <h3>Estimated Value:</h3>
                    <h4>${estvalue}</h4>
                  </div>
                </div>
              </div>
            </GridItem> */}
            <UserBalance />
            <WalletTransfer />
          </GridContainer>
        </div>
        <P2pUserWalletList />
      </div>
    </div>
  );
};

export default P2pwallet;
