// import package
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import {
    MenuItem, Select
} from "@material-ui/core/";
import { useSelector } from 'react-redux'

// import action
import { getStakeHistory, getStaking } from '../../actions/stakingAction';

// import lib
import { dateTimeFormat } from '../../lib/dateTimeHelper';

const columns = [
    {
        name: 'Date',
        selector: 'date',
        sortable: false,
        cell: record => {
            return dateTimeFormat(record.date)
        }
    },
    {
        name: 'Crypto',
        selector: 'crypto',
        sortable: false,
    },
    {
        name: 'Amount',
        selector: 'amount',
        sortable: false,
    },
    {
        name: 'APY',
        selector: 'apy',
        sortable: false,
    },
    {
        name: 'Interest Earned',
        selector: 'interestEarned',
        sortable: false,
    },
    {
        name: 'Type',
        selector: 'type',
        sortable: false,
    },

];
const StakeHistory = () => {
    // state
    const [loader, setLoader] = useState(true)
    const [list, setList] = useState([])
    const [record, setRecord] = useState({
        'data': [],
        'count': 0
    })
    const [filter, setFilter] = useState({
        'coin': 'all',
        'type': 'interest',
        'page': 1,
        'limit': 5
    })

    const { coin, type } = filter

    // redux
    const currencyData = useSelector(state => state.currency)

    // function
    const fetchHistory = async (reqData) => {
        try {
            setLoader(true)
            const { status, loading, result } = await getStakeHistory(reqData);
            setLoader(loading)
            if (status == 'success') {
                let newRecord = [];
                result.data && result.data.length > 0 && result.data.map((item, key) => {
                    console.log(reqData.type,'reqData.type')
                    newRecord.push({
                        "date": item.settleDate,
                        "crypto": item.coin,
                        "amount": `${item.stakeAmount} ${item.coin}`,
                        "apy": `${item.APY}%`,
                        "interestEarned": ['subscription', 'redemption'].includes(reqData.type) ? '-' : `${item.amount} ${item.coin}`,
                        "type": item.type
                    })
                })
                setRecord({
                    'count': result.count,
                    'data': newRecord,
                })
            }
        } catch (err) { }
    }

    const handlePageChange = page => {
        let filterData = { ...filter, ...{ 'page': page } }
        setFilter(filterData)
        fetchHistory(filterData)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
        setFilter(filterData)
        fetchHistory(filterData)
    };

    const handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let filterData = {
            ...filter,
            [name]: value
        }
        setFilter(filterData)
        fetchHistory(filterData)
    }

    const fetchStaking = async () => {
        try {
          const { status, loading, message, result } = await getStaking();
          setLoader(loading);
          if (status == "success") {
            setList(result);
          }
        } catch (err) {}
    };

    useEffect(() => {
        fetchHistory(filter)
        fetchStaking()
    }, [])
console.log(list,'listlistlist')
    return (
        <>
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <h3 class="dash_title mb-3">Staking History</h3>
                </div>
            </div>
            <div className="dashboard_box stakingHistoryTable">
                <div className="newUsersFilter contact_form settingsSelect mb-0">
                    <div className="newsSelectGroup">
                        <label>Filter by</label>
                        <Select
                            className="marginSpace"
                            value={coin}
                            name="coin"
                            onChange={handleChange}
                        >
                            <MenuItem value={'all'}>All</MenuItem>
                            {
                                currencyData && currencyData.length > 0 && currencyData.map((item, key) => {
                                    let findData = list.find(el => el.coin == item.currencySymbol)
                                    if ((item.type == 'crypto' || item.type == 'token') && findData) {
                                        return (
                                            <MenuItem value={item.currencySymbol} key={key}>
                                                {item.currencySymbol}
                                            </MenuItem>
                                        )
                                    }
                                })
                            }
                        </Select>
                        <Select
                            className="marginSpace"
                            value={type}
                            name="type"
                            onChange={handleChange}
                        >
                            <MenuItem value={'subscription'}>Subscription</MenuItem>
                            <MenuItem value={'redemption'}>Redemption</MenuItem>
                            <MenuItem value={'interest'}>Interest</MenuItem>
                        </Select>
                    </div>
                    <div className="newsSelectGroup">
                        {/* <button className="btn btn-outline text-uppercase py-1 m-0">Download PDF</button> */}
                    </div>
                </div>
                <DataTable
                    columns={columns}
                    data={record.data}
                    noHeader
                    progressPending={loader}
                    paginationTotalRows={record.count}
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5,10,15,20,500]}
                    pagination
                    paginationServer
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
            </div>
        </>
    )
}

export default StakeHistory;