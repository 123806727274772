// import package
import React, { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// import component
import FiatWithdraw from "./FiatWithdraw";
import FiatDeposit from "./FiatDeposit";
import CoinDeposit from "./CoinDeposit";
import CoinWithdraw from "./CoinWithdraw";
import INRDeposit from "./inrdeposit";

// import action
import { getAssetData } from "../../actions/walletAction";
import { getPairList } from "../../actions/spotTradeAction";
import { getUserDeposit } from "../../actions/walletAction";

// import lib
import { currencySymbol } from "../../lib/pairHelper";
import { toFixed } from "../../lib/roundOf";

const UserWalletList = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // redux-state
  const walletData = useSelector((state) => state.wallet);
  const priceConversion = useSelector((state) => state.priceConversion);
  const userSetting = useSelector((state) => state.userSetting);
  const pairData = useSelector((state) => state.tradePair);

  // state
  const [depositKey, setDepositKey] = useState(-1);
  const [withdrawKey, setWithdrawKey] = useState(-1);
  const [assetData, setAsstData] = useState({});
  const [pairList, setPairList] = useState([]);

  useEffect(() => {
    fetchPairList();
    // fetchUserDeposit()
  }, []);
  const fetchUserDeposit = async () => {
    await getUserDeposit();
  };
  const fetchPairList = async () => {
    try {
      const { status, loading, result } = await getPairList();
      if (status == "success") {
        setPairList(result, dispatch);
      } else {
      }
    } catch (err) {}
  };
  return (
    <>
      {walletData &&
        walletData.length > 0 &&
        walletData.map((item, key) => {
          let priceCNV;

          if (userSetting && priceConversion && priceConversion.length > 0) {
            priceCNV = priceConversion.find(
              (el) =>
                el.baseSymbol == item.currency.currencySymbol &&
                el.convertSymbol == userSetting.currencySymbol
            );
          }

          // console.log("ITESSDSADSDSD",item)
          return (
            <>
              <div className="dashboard_box mb-2">
                <div className="walletCard">
                  <div className="walletCardLeft">
                    <div className="currencyName">
                      <img
                        src={item.currency.currencyImage}
                        alt=""
                        className="img-fluid"
                      />
                      {item.currency.currencyName}
                    </div>
                    {item.currency.type != "fiat" ? (
                      <div className="currencyPrice">
                        {toFixed(item.spotwallet, 8)}{" "}
                        <small>
                          {item.currency.type == "crypto"
                            ? item.currency.CoinpaymentCode
                            : `${
                                item.currency.currencySymbol
                              }.${item.currency.tokenType.toUpperCase()}`}
                        </small>
                      </div>
                    ) : (
                      <div className="currencyPrice">
                        {toFixed(item.spotwallet, 8)}{" "}
                        <small>{item.currency.currencySymbol}</small>
                      </div>
                    )}
                    <div className="currencyPrice">
                      {priceCNV && (
                        <>
                          <small>
                            {currencySymbol(userSetting.currencySymbol)}
                          </small>{" "}
                          {toFixed(item.spotwallet * priceCNV.convertPrice, 2)}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="walletCardRight">
                    {item.currency.status == "active" && (
                      <div className="textLinkGroup">
                        [
                        <Link
                          href="#"
                          onClick={() => {
                            setDepositKey(key == depositKey ? -1 : key);
                            setAsstData(item);
                          }}
                        >
                          Deposit
                        </Link>
                        ] [
                        <Link
                          href="#"
                          onClick={() => {
                            setWithdrawKey(key == withdrawKey ? -1 : key);
                            setAsstData(item);
                          }}
                        >
                          Withdraw
                        </Link>
                        ]
                        {
                          // pairList && pairList.filter((details) =>{
                          //     if((details.firstCurrencySymbol == item.currency.currencySymbol) || (details.secondCurrencySymbol == item.currency.currencySymbol)){
                          //         console.log(details,'dtaillll')
                          //     }
                          // })
                          ["crypto", "token"].includes(item.currency.type) && (
                            <>
                              {" "}
                              [ <Link to={"/spot"}>Trade</Link> ]
                            </>
                          )
                        }
                      </div>
                    )}
                  </div>
                </div>

                <Collapse in={key == depositKey}>
                  <div className="collapseWallet">
                    <div className="contact_form settingsSelect mb-0">
                      {assetData &&
                        assetData.currency &&
                        ["crypto", "token"].includes(
                          assetData.currency.type
                        ) && <CoinDeposit assetData={item} />}

                      {assetData &&
                        assetData.currency &&
                        ["USD", "INR"].includes(assetData.currencySymbol) && (
                          //  assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) &&

                          <FiatDeposit assetData={item} />
                        )}
                      {/* {
                                             
                                             assetData && assetData.currency && ['INR'].includes(assetData.currencySymbol) && 
                                            //  assetData && assetData.currency && ['fiat'].includes(assetData.currency.type) && 
                                             
                                             <INRDeposit
                                                    assetData={item}
                                                />
                                            } */}
                    </div>
                  </div>
                </Collapse>

                <Collapse in={key == withdrawKey}>
                  <div className="collapseWallet">
                    <div className="contact_form settingsSelect mb-0">
                      {assetData &&
                        assetData.currency &&
                        ["crypto", "token"].includes(
                          assetData.currency.type
                        ) && <CoinWithdraw assetData={item} />}

                      {assetData &&
                        assetData.currency &&
                        ["fiat"].includes(assetData.currency.type) && (
                          <FiatWithdraw assetData={item} />
                        )}
                    </div>
                  </div>
                </Collapse>
              </div>
            </>
          );
        })}
    </>
  );
};

export default UserWalletList;
