// import package
import React, { useEffect, useState, useContext } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
// import context
import SocketContext from "../Context/SocketContext";
import clsx from "classnames";
import { toFixed } from "../../lib/roundOf";

// import action
import {
  getPairList,
  setPairList,
  setTradePair,
  getMarketPrice,
  setMarketPrice,
} from "../../actions/spotTradeAction";
import {
  getAssetByCurrency,
  setUserFirstCurrency,
  setUserSecondCurrency,
} from "../../actions/walletAction";

// import lib
import isEmpty from "../../lib/isEmpty";

const SelectPair = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tikerRoot } = useParams();
  const socketContext = useContext(SocketContext);

  // state
  const [pairLists, setPairLists] = useState([]);

  const pairListData = useSelector((state) => state.pairList);
  const [duppairlists, setduppairlists] = useState([]);
  const [duppairlistsoriginal, setduppairlistsoriginal] = useState([]);

  // function
  const fetchAssetByCurrency = async (spotPairId, type) => {
    try {
      const { status, loading, error, result } = await getAssetByCurrency(
        spotPairId
      );
      if (status == "success") {
        if (type == "firstCurrency") {
          setUserFirstCurrency(result, dispatch);
        } else if (type == "secondCurrency") {
          setUserSecondCurrency(result, dispatch);
        }
      } else {
      }
    } catch (err) {}
  };

  const fetchPairList = async () => {
    try {
      const { status, loading, result } = await getPairList();
      if (status == "success") {
        setPairList(result, dispatch);
        if (result && result.length > 0) {
          if (isEmpty(tikerRoot)) {
            let pair = `${result[0].firstCurrencySymbol}_${result[0].secondCurrencySymbol}`;
            history.push("/spot/" + pair);
            await fetchAssetByCurrency(
              result[0].firstCurrencyId,
              "firstCurrency"
            );
            await fetchAssetByCurrency(
              result[0].secondCurrencyId,
              "secondCurrency"
            );
            await setMarketPrice(result[0], dispatch);
            await setTradePair(result[0], dispatch);
            // getMarketPrice(result[0]._id, dispatch);
          } else {
            let currency = tikerRoot.split("_");
            let pairDetail = result.find(
              (el) =>
                el.firstCurrencySymbol == currency[0] &&
                el.secondCurrencySymbol == currency[1]
            );
            await fetchAssetByCurrency(
              pairDetail.firstCurrencyId,
              "firstCurrency"
            );
            await fetchAssetByCurrency(
              pairDetail.secondCurrencyId,
              "secondCurrency"
            );

            // getMarketPrice(pairDetail._id, dispatch);
            await setMarketPrice(pairDetail, dispatch);
            await setTradePair(pairDetail, dispatch);
          }
        }
      } else {
      }
    } catch (err) {}
  };

  const handlePairChange = async (pairData) => {
    let pair = `${pairData.firstCurrencySymbol}_${pairData.secondCurrencySymbol}`;
    history.push("/spot/" + pair);
    window.location.reload(false);
  };

  useEffect(() => {
    if (pairListData && pairListData.length > 0) {
      setPairLists(pairListData);
      // setduppairlists(pairListData)

      var newarray = [];
      for (var i = 0; i < pairListData.length; i++) {
        if (pairListData[i].secondCurrencySymbol == "BTC") {
          newarray.push(pairListData[i]);
        }
      }
      setduppairlists(newarray);
      setduppairlistsoriginal(newarray);
    }
  }, [pairListData]);

  useEffect(() => {
    fetchPairList();
  }, []);

  //   useEffect(() => {
  //     if (pairListData && pairListData.length > 0) {
  //       setPairLists(pairListData);
  //       setOriginalPairList(pairListData)
  //     }
  //   }, [pairListData]);

  //   useEffect(() => {
  //     if (!isEmpty(pairData)) {
  //       // socket
  //       socketContext.socket.on("marketPrice", (result) => {
  //         console.log(result, "resultresultresult");
  //         if (result.pairId == pairData.pairId) {
  //           setMarketPrice(result.data, dispatch);
  //           let tempPairList = pairLists;
  //           let pairIndex = tempPairList.findIndex(
  //             (el) => el._id == result.pairId
  //           );
  //           if (pairIndex >= 0) {
  //             tempPairList[pairIndex] = {
  //               ...tempPairList[pairIndex],
  //               ...{
  //                 markPrice: result.data.markPrice,
  //                 change: result.data.change,
  //               },
  //             };
  //             // console.log("pairListspairLists",pairData)
  //             for (let i = 0; i < pairLists.length; i++) {
  //               if (
  //                 pairLists[i].firstCurrencySymbol ==
  //                 pairData.secondCurrencySymbol
  //               ) {
  //                 if (pairLists[i].secondCurrencySymbol == "INR") {
  //                   console.log("pairLists", pairLists[i]);
  //                   setonecryptotoinr(pairLists[i].markPrice);
  //                 }
  //               }
  //             }

  //             // console.log("pairDatapairDatapairData", pairLists, pairData);
  //             // setoneusdtprice()
  // //original befor change search otption
  //             // setPairLists(tempPairList);
  //           }
  //         }
  //       });

  //       socketContext.socket.on("inrconversion", (result) => {
  //         console.log(
  //           "inrconversioninrconversioninrconversion",
  //           result,
  //           tickerData.markPrice,
  //           result.data
  //         );
  //         if (result.data[pairData.secondCurrencySymbol]) {
  //           var usdtprice = result.data[pairData.secondCurrencySymbol];
  //           setinrconvertprice(usdtprice);
  //           setinrmarkprice(tickerData.markPrice * usdtprice);
  //         }
  //       });
  //     }
  //   }, [pairData]);

  const pairSearch = async (e) => {
    e.preventDefault();
    if (e.target.value) {
      let result = await duppairlists.filter(
        (el) =>
          el.firstCurrencySymbol.includes(e.target.value.toUpperCase()) ||
          el.secondCurrencySymbol.includes(e.target.value.toUpperCase())
      );
      if (result) {
        setduppairlists(result);
      }
    } else {
      setduppairlists(duppairlistsoriginal);
    }
  };
  const ordertype_changes = (type) => {
    var newarray = [];
    for (var i = 0; i < pairLists.length; i++) {
      if (pairLists[i].secondCurrencySymbol == type) {
        newarray.push(pairLists[i]);
      }
    }
    setduppairlists(newarray);
    setduppairlistsoriginal(newarray);
  };

  // console.log("pairDatapairDatapairData",pairData)
  // let price = priceConversion.find((el=>el.baseSymbol == ))
  return (
    <div class="tablePairSelect">
      <nav className="tradeFulltabbedTable">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            class="nav-item nav-link active"
            id="nav-BTC-tab"
            data-toggle="tab"
            href="#nav-BTC"
            role="tab"
            onClick={() => {
              ordertype_changes("BTC");
            }}
            aria-controls="nav-BTC"
            aria-selected="false"
          >
            BTC
          </a>
          <a
            class="nav-item nav-link"
            id="nav-USDT-tab"
            data-toggle="tab"
            href="#nav-USDT"
            role="tab"
            onClick={() => {
              ordertype_changes("USDT");
            }}
            aria-controls="nav-USDT"
            aria-selected="false"
          >
            USDT
          </a>
          <a
            class="nav-item nav-link"
            id="nav-INR-tab"
            data-toggle="tab"
            href="#nav-INR"
            role="tab"
            onClick={() => {
              ordertype_changes("INR");
            }}
            aria-controls="nav-INR"
            aria-selected="true"
          >
            INR
          </a>
        </div>
      </nav>
      <div class="form-group p-2 my-2">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            placeholder="Search pair"
            onChange={pairSearch}
          />
          <div class="input-group-append">
            <span class="btnType1">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="tab-content px-3 px-sm-0" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-INR"
          role="tabpanel"
          aria-labelledby="nav-INR-tab"
        >
          <div className="deopdown_menu clss_789table">
            <Scrollbars style={{ width: "100%", height: 800 }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Pair</th>
                    <th>Price</th>

                    <th>Change</th>
                  </tr>
                </thead>

                <tbody>
                  {duppairlists &&
                    duppairlists.length > 0 &&
                    duppairlists.map((item, key) => {
                      return (
                        <tr key={key} onClick={() => handlePairChange(item)}>
                          <td>
                            <p>
                              {/* <i class="fas fa-star"></i> */}
                              {item.firstCurrencySymbol}
                              <span>/{item.secondCurrencySymbol}</span>
                            </p>
                          </td>
                          <td className="balance_amt_detail">
                            <p>
                              <span
                                className={clsx({
                                  span_menu_gerrn: item.change >= 0,
                                  span_menu_red: item.change < 0,
                                })}
                              >
                                {toFixed(item.markPrice, item.secondFloatDigit)}
                              </span>
                              {/* <span>/$314.5</span> */}
                            </p>
                          </td>
                          <td
                            className={clsx({
                              span_menu_gerrn: item.change >= 0,
                              span_menu_red: item.change < 0,
                            })}
                          >
                            {toFixed(item.change, 2)}%
                          </td>
                          {/* <td>
                            <span className="span_menu_gerrn">
                              {item.change}
                            </span>
                          </td> */}
                          {/* <td className="buy_button_op">0</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Scrollbars>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-BTC"
          role="tabpanel"
          aria-labelledby="nav-BTC-tab"
        >
          <div className="deopdown_menu clss_789table">
            <Scrollbars style={{ width: "100%", height: 800 }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Pair</th>
                    <th>Price</th>
                    <th>Change</th>
                    {/* <th>24h Volume</th> */}
                  </tr>
                </thead>
                <tbody>
                  {duppairlists &&
                    duppairlists.length > 0 &&
                    duppairlists.map((item, key) => {
                      return (
                        <tr key={key} onClick={() => handlePairChange(item)}>
                          <td>
                            <p>
                              {/* <i class="fas fa-star"></i> */}
                              {item.firstCurrencySymbol}
                              <span>/{item.secondCurrencySymbol}</span>
                            </p>
                          </td>
                          <td className="balance_amt_detail">
                            <p>
                              <span
                                className={clsx({
                                  span_menu_gerrn: item.change >= 0,
                                  span_menu_red: item.change < 0,
                                })}
                              >
                                {toFixed(item.markPrice, item.secondFloatDigit)}
                              </span>
                              {/* <span>/$314.5</span> */}
                            </p>
                          </td>
                          <td
                            className={clsx({
                              span_menu_gerrn: item.change >= 0,
                              span_menu_red: item.change < 0,
                            })}
                          >
                            {toFixed(item.change, 2)}%
                          </td>
                          {/* <td className="buy_button_op">0</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Scrollbars>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="nav-USDT"
          role="tabpanel"
          aria-labelledby="nav-USDT-tab"
        >
          <div className="deopdown_menu clss_789table">
            <Scrollbars style={{ width: "100%", height: 800 }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Pair</th>
                    <th>Price</th>

                    <th>change</th>
                    {/* <th>24h Volume</th> */}
                  </tr>
                </thead>
                <tbody>
                  {duppairlists &&
                    duppairlists.length > 0 &&
                    duppairlists.map((item, key) => {
                      return (
                        <tr key={key} onClick={() => handlePairChange(item)}>
                          <td>
                            <p>
                              {/* <i class="fas fa-star"></i> */}
                              {item.firstCurrencySymbol}
                              <span>/{item.secondCurrencySymbol}</span>
                            </p>
                          </td>
                          <td className="balance_amt_detail">
                            <p>
                              <span
                                className={clsx({
                                  span_menu_gerrn: item.change >= 0,
                                  span_menu_red: item.change < 0,
                                })}
                              >
                                {toFixed(item.markPrice, item.secondFloatDigit)}
                              </span>
                              {/* <span>/$314.5</span> */}
                            </p>
                          </td>
                          <td
                            className={clsx({
                              span_menu_gerrn: item.change >= 0,
                              span_menu_red: item.change < 0,
                            })}
                          >
                            {toFixed(item.change, 2)}%
                          </td>
                          {/* <td className="buy_button_op">0</td> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPair;
